//This sets the title in the browser window as well as the header bar at the top of the application based on each page's routing module. For example, in feedback-percentage-routing-module.cs, it defines the title for the component.
import { Component } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-title',
  template: '<span></span>'
})
export class TitleComponent {
  public titleString: string;
  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {
    this.router.events
      .pipe(filter((event: any): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(() => {
        let currentRoute = this.route.root;
        let title = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(routes => {
            if (routes.outlet === 'primary') {
              title = routes.snapshot.data.title;
              currentRoute = routes;
            }
          });
        } while (currentRoute);
        if (title !== undefined) {
          //This is for the title that shows up in the top of the browser (e.g. the part of the tab that sticks up and you can see even when you are looking at another tab).
          this.titleService.setTitle('BuildSAGE | ' + title);
          //This is for the title that shows up near the top of the page to the right of the GR logo. This used to be set by setting the variable titleString, which is the Angular way, but then we wanted to sometimes update this title from another place (admin compoenent), and it didn't play nicely to do it this way. So we are using old fashioned JS here.
          const t = document.getElementById("titleString");
          if (t)
            t.innerText = title;
          //this.titleString = title;
        }
      });
  }
}
