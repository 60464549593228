// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig:{
    	apiKey: "AIzaSyAqSrqa72aHHaEzHTNTuaWGTgb84BYbyiM",
  	authDomain: "buildsageprodnotifications.firebaseapp.com",
  	databaseURL: "https://buildsageprodnotifications-default-rtdb.firebaseio.com",
  	projectId: "buildsageprodnotifications",
  	storageBucket: "buildsageprodnotifications.appspot.com",
  	messagingSenderId: "475820989456",
  	appId: "1:475820989456:web:9f548cb2b983409780be95"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
