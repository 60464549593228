// This file includes everything needed to call the server's web service API methods. The functions here all start with a word that's like a namespace and then an underscore, which denotes the controller on the server. For example, authentication_ValidateUser will call the API method ValidateUser that is part of the Authentication controller.
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MessageBoxComponent} from '../message-box/message-box.component';

// For the API URL, we either get it from config.js or we get it passed into the constructor as the baseURL. Currently it's the former, as the latter only works in certain circumstances (see comment below about baseURL).
declare var webApiBaseUrl: string; // This tells the compiler that it's a global variable coming from another file. In our case, it's coming from config.js.

@Injectable({
  providedIn: 'root'
})
export class WebService {
  // Only use the baseURL param if the baseURL for the web API is the same as for the UI (i.e. index.html). The baseURL string passed in comes from main.ts, which grabs the URL from the <base> element in index.html.
  constructor(private http: HttpClient, @Inject('BASE_URL') baseURL: string) {
    // webApiBaseUrl = baseURL;
    if (!webApiBaseUrl) {
      alert('config.js not loaded');
    }
  }

  // Authentication Methods
  authentication_ValidateUser(email: string, password: string, timeZoneOffsetMinutes: number, browserGuid: string, passcode: string, registerBrowser: boolean, passcodeDelivery: string): Observable<ValidateUserResponse> {
    const requestParams = new ValidateUserRequest(email, password, timeZoneOffsetMinutes, browserGuid, passcode, registerBrowser, passcodeDelivery);
    return this.http.post<ValidateUserResponse>(webApiBaseUrl + 'api/Authentication/ValidateUser', requestParams);
  }

  authentication_DeleteUserAuth(browserGuid: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Authentication/DeleteUserAuth?browserGuid=' + browserGuid, null);
  }

  // This will send a message to the server that the user is logging out. We use native AJAX support rather than the typcial Anular method because I found that using Angular made the browser pause for a second before navigating to the login page.
  authentication_LogUserLogout() {
    const Http = new XMLHttpRequest();
    const url = webApiBaseUrl + 'api/Authentication/LogUserLogout?userNo=' + sessionStorage.userNo;
    Http.open('GET', url);
    Http.send();
  }

  // Report Methods
  reports_getContractorsGraph(request: ContractorRequest):Observable<ContractorResponse[]>{
    return this.http.post<ContractorResponse[]>(webApiBaseUrl + 'api/Reports/GrowthStatisticsContractors/', request);
  }

  reports_getHomeownersGraph(request: HomeownerRequest):Observable<HomeownerResponse[]>{
    return this.http.post<HomeownerResponse[]>(webApiBaseUrl + 'api/Reports/GrowthStatisticsHomeowners/', request);
  }

  reports_getProjectsGraph(request: ProjectGraphRequest):Observable<ProjectGraphResponse[]>{
    return this.http.post<ProjectGraphResponse[]>(webApiBaseUrl + 'api/Reports/GrowthStatisticsProjects/', request);
  }

  reports_getContractsSignedGraph(request: ContractsSignedRequest):Observable<ContractsSignedResponse[]>{
    return this.http.post<ContractsSignedResponse[]>(webApiBaseUrl + 'api/Reports/GrowthStatisticsContractsSigned/', request);
  }

  reports_getProjectStages(request: ProjectStagesRequest):Observable<ProjectStagesResponse>{
    return this.http.post<ProjectStagesResponse>(webApiBaseUrl + 'api/Reports/StageBreakdown/', request);
  }

  reports_getProjectStatistics(request: ProjectStatisticsRequest): Observable<ProjectStatisticsResponse[]> {
    return this.http.post<ProjectStatisticsResponse[]>(webApiBaseUrl + 'api/Reports/GetProjectStatistics/', request);
  }

  reports_getBudgeting(request: BudgetingRequest): Observable<BudgetingResponse> {
    return this.http.post<BudgetingResponse>(webApiBaseUrl + 'api/Reports/GetBudgeting/', request);
  }

  reports_GetKpiDashboard(): Observable<KPIDashBoardStats> {
    return this.http.post<KPIDashBoardStats>(webApiBaseUrl + 'api/Reports/GetKpiDashboard/', null);
  }

  users_getUserList(userType: string, includeDisabled = true): Observable<UserListResponse> {
    return this.http.get<UserListResponse>(webApiBaseUrl + `api/Users/GetUserList?userType=${userType}&includeDisabled=${includeDisabled}`);
  }

  // User Methods
  users_SendEmailToResetPassword(email: string, urlBase: string): Observable<ApiResponse> {
    const requestParams = new ResetPasswordRequest(email, urlBase);
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Users/SendEmailToResetPassword', requestParams);
  }

  users_GetUsersByType(roleNo: number, userNo = null): Observable<UsersResponse> {
    return this.http.post<UsersResponse>(webApiBaseUrl + `api/Users/GetUsersByType?roleNo=${roleNo}&serviceAreaNos=${sessionStorage.serviceAreaNos}&userNo=${userNo}`, null);
  }

  users_GetUser(): Observable<UserResponse> {
    return this.http.post<UserResponse>(webApiBaseUrl + 'api/Users/GetUser', null);
  }

  users_AddUser(user: User): Observable<ApiResponse> {
    return this.http.post<UsersResponse>(webApiBaseUrl + 'api/Users/AddUser', user);
  }

  users_EditUser(user: User): Observable<ApiResponse> {
    return this.http.post<UsersResponse>(webApiBaseUrl + 'api/Users/EditUser', user);
  }

  users_DeleteUser(userNo: number): Observable<ApiResponse> {
    return this.http.post<UsersResponse>(webApiBaseUrl + 'api/Users/DeleteUser?userNo=' + userNo, null);
  }

  users_EditContractor(contractor: Contractor, newFiles: object[]): Observable<UsersResponse> {
    let formData = new FormData();
    formData.append('Contractor', JSON.stringify(contractor));
    newFiles.forEach(doc =>  {
      const file: File = <File>doc;
      formData.append(JSON.stringify(doc), file, file.name);
    })
    return this.http.post<UsersResponse>(webApiBaseUrl + 'api/Users/EditContractor', formData);
  }

  users_ChangePasswordWithoutOldPassword(email: string, newPassword: string, guid: string): Observable<ApiResponse> {
    const requestParams = new ChangePasswordRequest(email, '', newPassword);
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Users/ChangePasswordWithoutOldPassword?guid=' + guid, requestParams);
  }

  users_ChangePassword(email: string, oldPassword: string, newPassword: string): Observable<ApiResponse> {
    const requestParams = new ChangePasswordRequest(email, oldPassword, newPassword);
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Users/ChangePassword', requestParams);
  }

  users_SendReferenceEmail(applicantName: string, userNo: number, referenceName: string, referenceEmail: string, referenceNo: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Users/SendReferenceEmail?applicantName=' + applicantName + '&userNo=' + userNo + '&referenceName=' + referenceName + '&referenceEmail=' + referenceEmail + '&referenceNo=' + referenceNo, null);
  }

  users_SendEmail(emailType: number, emailAddress: string, emailSubject: string = "", emailBody: string = ""): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Users/SendEmail?emailType=' + emailType + '&emailAddress=' + emailAddress + '&emailSubject=' + emailSubject, emailBody);
  }

  // Project Methods
  projects_GetProjects(includeTasks: boolean, userNoHomeowner: number = -1, userNoAdmin: number = -1, userNoContractor: number = -1): Observable<ProjectsResponse> {
    // Find a way to get the serviceArea number
    // I pull the direct array position because it is now coming in differently
    let url = webApiBaseUrl + `api/Projects/GetProjectsAndTasks?includeTasks=${includeTasks}&serviceAreaNos=${sessionStorage.serviceAreaNos}`;
    if (userNoHomeowner != -1) {
      url += '&UserNoHomeowner=' + userNoHomeowner;
    } else if (userNoAdmin != -1) {
      url += '&UserNoAdmin=' + userNoAdmin;
    } else if (userNoContractor != -1) {
      url += '&UserNoContractor=' + userNoContractor;
    }
    return this.http.post<ProjectsResponse>(url, null);
  }

  projects_GetProject(projectNo: number): Observable<ProjectResponse> {
    return this.http.post<ProjectResponse>(webApiBaseUrl + 'api/Projects/GetProject?projectNo=' + projectNo, null);
  }

  projects_SaveProjectDetails(project: Project, didStatusChange = false): Observable<ProjectResponse> {
    return this.http.post<ProjectResponse>(webApiBaseUrl + `api/Projects/SaveProjectDetails?didStatusChange=${didStatusChange}`, project);
  }

  projects_DeleteProject(project: Project): Observable<ApiResponse> {
    project.projectStatusNo = ProjectStatus.Deleted;
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Projects/SaveProjectDetails?didStatusChange=true', project);
  }

  projects_SaveProjectAndUserAnswers(project: Project, files: object): Observable<ProjectResponse> {
    var formData = new FormData();
    formData.append('Project', JSON.stringify(project));
    for (const doc in files) {
      if (files[doc]) {
        const file: File = <File>files[doc];
        formData.append(doc, file, file.name);
      }
    }

    return this.http.post<ProjectResponse>(webApiBaseUrl + 'api/Projects/SaveProjectAndUserAnswers', formData);
  }

  projects_SaveScopeOfWorkJobs(scopeOfWorkJobs: ScopeOfWorkJob[]): Observable<ScopeOfWorkResponse> {
    const param = { scopeOfWorkJobs: scopeOfWorkJobs};
    return this.http.post<ScopeOfWorkResponse>(webApiBaseUrl + 'api/Projects/SaveScopeOfWorkJobs', param);
  }

  projects_deleteScopeOfWorkJob(ScopeOfWorkJobNo: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Projects/DeleteScopeOfWorkJob?ScopeOfWorkJobNo=' + ScopeOfWorkJobNo, null);
  }

  projects_SaveRoom(room: Room, img: object): Observable<ApiResponse> {
    const formData = new FormData();
    formData.append("Room", JSON.stringify(room));
    // something here throws an error
    if (img != null) {
      const file: File = <File>img;
      formData.append('img', file, file.name);
    }
    return this.http.post<ApiResponse>(webApiBaseUrl + `api/Projects/SaveRoom`, formData);
  }

  projects_deleteRoom(roomNo: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Projects/DeleteRoom?roomNo=' + roomNo, null);
  }

  projects_GetUserDecisionTree(projectNo: number): Observable<UserAnswersResponse> {
    return this.http.post<UserAnswersResponse>(webApiBaseUrl + 'api/Projects/GetUserDecisionTree?projectNo=' + projectNo, null);
  }

  // Get contract PDF
  // tslint:disable-next-line:max-line-length
  projects_GetContract(responseContentsInline: boolean, projectNo: number, userNoHomeowner: number, userNoContractor: number, saveDocument: boolean): Observable<any> {
    const url = webApiBaseUrl + `api/Projects/GetContract?responseContentsInline=${responseContentsInline}&projectNo=${projectNo}&userNoHomeowner=${userNoHomeowner}&userNoContractor=${userNoContractor}&saveDocument=${saveDocument}`;
    // @ts-ignore
    return this.http.post<any>(url, null, {responseType: 'blob'});
  }

  projects_SaveProjectStatus(projectNo: number, projectStatusNo: number): Observable<ApiResponse> {
    // tslint:disable-next-line:max-line-length
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Projects/SaveProjectStatus?projectNo=' + projectNo + '&projectStatusNo=' + projectStatusNo, null);
  }

  // Inspirations methods
  inspirations_GetInspirations(projectNo: number): Observable<InspirationsResponse> {
    return this.http.post<InspirationsResponse>(webApiBaseUrl + 'api/Inspiration/GetInspirations?projectNo=' + projectNo, null);
  }

  inspirations_SaveInspiration(data: FormData): Observable<any> {
    return this.http.post<any>(webApiBaseUrl + 'api/Inspiration/SaveInspiration', data);
  }

  inspirations_DeleteInspiration(InspirationNo: number): Observable<any> {
    return this.http.post<any>(webApiBaseUrl + 'api/Inspiration/DeleteInspiration?InspirationNo=' + InspirationNo, null);
  }

  // Notes Methods
  notes_GetNotes(projectNo: number): Observable<NotesResponse> {
    return this.http.post<NotesResponse>(webApiBaseUrl + 'api/Notes/GetNotes?projectNo=' + projectNo, null);
  }

  notes_SaveNote(note: object, newFiles: object): Observable<NotesResponse> {
    const formData = new FormData();
    formData.append('Note', JSON.stringify(note));
    for (const f in newFiles) {
      const file: File = <File>newFiles[f];
      formData.append(f, file, file.name);
    }
    return this.http.post<NotesResponse>(webApiBaseUrl + 'api/Notes/SaveNote', formData);
  }

  notes_DeleteNote(noteNo: number): Observable<NotesResponse> {
    return this.http.post<NotesResponse>(webApiBaseUrl + 'api/Notes/DeleteNote?noteNo=' + noteNo, null);
  }

  // Important Docs Methods
  importantDocuments_GetImportantDocuments(projectNo: number): Observable<ImportantDocumentsResponse> {
    return this.http.post<ImportantDocumentsResponse>(webApiBaseUrl + 'api/ImportantDocuments/GetImportantDocuments?projectNo=' + projectNo, null);
  }

   // Important Docs Types
  importantDocuments_GetImportantDocumentsTypes(): Observable<ImportantDocumentTypesResponse> {
    return this.http.get<any>(webApiBaseUrl + 'api/ImportantDocuments/GetImportantDocumentTypes');
  }

  importantDocuments_SaveImportantDocument(importantDocument: ImportantDocument, newFiles: object): Observable<ApiResponse> {
    const formData = new FormData();
    formData.append('ImportantDocument', JSON.stringify(importantDocument));
    for (const doc in newFiles) {
      const file: File = <File>newFiles[doc];
      formData.append(doc, file, file.name);
    }
    return this.http.post<ApiResponse>(webApiBaseUrl + `api/ImportantDocuments/SaveImportantDocument`, formData);
  }

  importantDocuments_DeleteImportantDocument(importantDocumentNo: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + `api/ImportantDocuments/DeleteImportantDocument?importantDocumentNo=` + importantDocumentNo, null);
  }

  // Milestones methods
  milestones_GetMilestones(projectNo: number): Observable<MilestonesResponse> {
    return this.http.post<MilestonesResponse>(webApiBaseUrl + 'api/Milestones/GetMilestones?projectNo=' + projectNo, null);
  }

  milestones_SaveMilestone(milestone: Milestone): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Milestones/SaveMilestone', milestone);
  }

  milestones_DeleteMilestone(milestoneNo: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Milestones/DeleteMilestone?milestoneNo=' + milestoneNo , null);
  }

  // Visits methods
  milestones_DeleteVisit(visitNo: number): Observable<MilestonesResponse> {
    return this.http.post<MilestonesResponse>(webApiBaseUrl + 'api/Milestones/DeleteVisit?visitNo=' + visitNo, null);
  }

  // Notifications methods
  notifications_GetNotificationsByUser(userNo: number): Observable<NotificationsResponse> {
    return this.http.post<NotificationsResponse>(webApiBaseUrl + `api/Notifications/GetNotificationsByUser?userNo=` + userNo, null);
  }

  notifications_ReadNotifications(notificationNos: number[]): Observable<ApiResponse> {
    return this.http.post<NotificationsResponse>(webApiBaseUrl + `api/Notifications/ReadNotifications`, notificationNos);
  }

  notifications_ClearNotifications(notificationNos: number[]): Observable<ApiResponse> {
    return this.http.post<NotificationsResponse>(webApiBaseUrl + `api/Notifications/ClearNotifications`, notificationNos);
  }

  notifications_AddUpdateNotification(notification: Notification, sendExternalNotification: boolean = true): Observable<ApiResponse> {
    return this.http.post<NotificationsResponse>(webApiBaseUrl + `api/Notifications/AddUpdateNotification?sendExternalNotification=` + sendExternalNotification.toString(), notification);
  }

  messaging_GetMessages(projectNo: number, messageTypeNo: number, lastMessageNo?: number, messageFlags: string = "", messageText: string = ""): Observable<MessagesResponse> {
    let endpoint = `projectNo=${projectNo}&messageTypeNo=${messageTypeNo}&messageFlags=${messageFlags}&messageText=${messageText}`;
    if (lastMessageNo != null) {
      endpoint += `&lastMessageNo=${lastMessageNo}`;
    }
    return this.http.post<MessagesResponse>(webApiBaseUrl + 'api/Messaging/GetMessages?' + endpoint, null);
  }

  messaging_SaveMessage(message: object, newFiles: File[]): Observable<MessagesResponse> {
    let formData = new FormData();
    formData.append('Message', JSON.stringify(message));
    for (var i = 0; i < newFiles.length; i++) {
      formData.append((i + 1).toString(), newFiles[i], newFiles[i].name);
    }
    return this.http.post<MessagesResponse>(webApiBaseUrl + 'api/Messaging/SaveMessage', formData);
  }

  // Tree Methods
  tree_GetTreeList(includeDisabled: boolean = false): Observable<TreeListResponse> {
    return this.http.post<TreeListResponse>(webApiBaseUrl + 'api/Tree/GetTreeList?includeDisabled=' + includeDisabled, null);
  }

  tree_GetBlankTree(treeNo: number): Observable<TreeResponse> {
    return this.http.post<TreeResponse>(webApiBaseUrl + 'api/Tree/GetBlankDecisionTree?treeNo=' + treeNo, null);
  }

  tree_GetTreeQuestionTypes(): Observable<TreeQuestionTypeResponse> {
    return this.http.post<TreeQuestionTypeResponse>(webApiBaseUrl + 'api/Tree/GetTreeQuestionTypes', null);
  }

  tree_SaveTree(tree: object, img: object): Observable<ApiResponse> {
    let formData = new FormData();
    formData.append('Tree', JSON.stringify(tree));
    if (img != null) {
    let file: File = <File>img;
    formData.append('img', file, file.name);

    }
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Tree/SaveDecisionTree', formData);
  }

  tree_DisableTree(treeNo: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + 'api/Tree/SetTreeStatus?treeNo=' + treeNo + '&treeStatusNo=2', null);
  }
  // Tasks methods

  tasks_GetTasks(projectNo: number, roleNo: number = -1): Observable<GetTasksResponse> {
    return this.http.post<GetTasksResponse>(webApiBaseUrl + 'api/Tasks/GetTasks?projectNo=' + projectNo + (roleNo != -1 ? '&roleNo=' + roleNo: ''), null);
  }

  tasks_SaveTask(task: Task): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(webApiBaseUrl + `api/Tasks/TransitTask`, task);
  }
}

// base class for response objects
export class ApiResponse {
  errorMessage: string;
  errorCode: number;

  static checkStatus(resp: ApiResponse): boolean {
    if (resp.errorCode != 0) {
      if (MessageBoxComponent.staticInstance) {
        MessageBoxComponent.staticInstance.show('Error', resp.errorMessage, false);
      }
      return false;
    }
    return true;
  }
}

export class ValidateUserResponse extends ApiResponse {
  userValidated: boolean; // this is passed back from the server, but we don't actually use it because we are able to tell from other fields if the user is validated.
  browserRegistered: boolean;
  userNo: number;
  serviceAreaNos: string;
  firstName: string;
  lastName: string;
  validationToken: string;
  browserGuid: string;
  tokenExpirationSeconds: number;
  userStatusNo: number;
  officeManagerEditPermission: boolean;
  phone: string;
  email: string;
  browserMfaExpiresDays: string;
  authorityRoles: string[];
  serviceAreas: ServiceArea[];
  taskCategories: TaskCategory[];
  projectStatuses: Option[];
}

export class Option {
  constructor( // These params will automatically become the properties of this class
    public optionNo: number,
    public optionName: string,
  ) { }
}

export class ValidateUserRequest {
  constructor( // These params will automatically become the properties of this class
    public email: string,
    public password: string,
    public TimeZoneOffsetMinutes: number,
    public browserGuid: string,
    public passcode: string,
    public registerBrowser: boolean,
    public passcodeDelivery: string
  ) { }
}

export class ChangePasswordRequest {
  constructor( // These params will automatically become the properties of this class
    public email: string,
    public oldPassword: string,
    public newPassword: string
  ) { }
}

export class ResetPasswordRequest {
  constructor( // These params will automatically become the properties of this class
    public email: string,
    public urlBase: string
  ) { }
}

export class ServiceArea {
  public serviceAreaNo: number;
  public serviceAreaName: string;
  public serviceAreaZipCode: string;
}

export class User {
  constructor( // These params will automatically become the properties of this class
    public userNo: number,
    public serviceAreaNos: string, // comma-delimited list
    public roleNo: number,
    public userStatusNo: number,
    public email: string,
    public firstName: string,
    public lastName: string,
    public phone: string,
    public enableEmailNotifications: boolean,
    public enableTextNotifications: boolean,
    public secondaryFirstName: string,
    public secondaryLastName: string,
    public businessName: string,
    public secondaryPhone: string,
    public officeManagerEditPermission: boolean,
    public addresses: Address[],
    public isLockedOut: boolean,
    public deletedUTC: Date,
    public createdUTC: Date,
    public lastLoginUTC: Date,
    public TotalBudgetOfProjects: number,
    public TotalNumberOfActiveProjects: number,
    public userRoles: string,
    public password: string,
    public submittedProjects: string[],
    public activeProjects: string[],
    public completedProjects: string[],
    public serviceAreaNames: string, // This is not in the DTO returned by the API. But we set it on the UI.
  ) { }

  static create() {
    return new User(0, '', 1, 1, '', '', '', '', true, false, '', '', '', '', null, [], false, null, null, null, null, null, '', '', [], [], [], '');
  }

  static statusArrayUsers = [
    new Option(0, 'Disabled'),
    new Option(1, 'Enabled')
  ];

  

  static statusArrayContractors = [
    new Option(0, "Disabled"),
    new Option(1, "Approved"),
    new Option(2, "Account Just Created"),
    new Option(3, "Application Submitted, Part 1"),
    new Option(4, "Application Part 1 Approved"),
    new Option(5, "Application Submitted, Part 2"),
    new Option(6, "Declined"),
    new Option(7, "Temporarily disabled"),
  ];
}



export class UsersResponse extends ApiResponse {
  public users: User[];
  public contractors: Contractor[];
}

export class UserResponse extends ApiResponse {
  public user: User;
  public contractor: Contractor;
}

export class GetStringResponse extends ApiResponse {
  public message: string;
}

export class TaskCategory {
  constructor(
    public taskCategoryNo: number,
    public roleNo: number,
    public taskCategoryText: string,
    public currentStatusNo: number,
    public nextStatusNo: number,
  ) { }
}

export class Project {
  projectNo: number = null;
  treeNo: number = 0;
  projectType: string = '';
  userNoHomeowner: number = 0;
  homeownerName: string = '';
  userNoAdmin: number = null;
  buildSageRepName: string = '';
  userNoContractor: number = null;
  contractorName: string = '';
  projectName: string = '';
  projectStatusNo: number = 13;
  projectStatusDescription: string = '';
  address: string = null;
  city: string = '';
  stateCode: string = '';
  zipCode: string = '';
  budget: number = 0;
  estimatedBudget: number = 0;
  startDate: string = null;
  months: number = 0;
  weeks: number = 0;
  days: number = 0;
  endDate: string = null;
  lastUpdatedUTC: string = '';
  firstHomeownerAvailabilityUTC: string = '';
  secondHomeownerAvailabilityUTC: string = '';
  thirdHomeownerAvailabilityUTC: string = '';
  contractorSelectedTimeNo: number = 0;
  addressNo: number = null;
  lastMessageUTC: string = '';
  proposalEstimateTimeNo: number = 0;
  additionalNotes: string = '';
  isArchitectAssigned: boolean = false;
  architectName: string = null;
  isFixedPrice: boolean = false;
  fixedPrice: number = null;
  fixedPriceText: string = '';
  contractorFee: number = null;
  contractorFeeText: string = null;
  notToExceedPrice: number = null;
  notToExceedPriceText: string = null;
  isEscrowFreeText: boolean = false;
  escrowFreeText: string = null;
  contractYear: number = null;
  contractMonth: string = null;
  contractDay: number = null;
  projectDescription: string = null;
  allowances: string = null;
  isActive: boolean = false;
  displayThumbnailUrl: string = null;
  signerName: string = null;
  signerRole: string = null;
  didHomeownerSign: boolean = false;
  didContractorSign: boolean = false;
  createdUTC: Date = null;
  completedUTC: Date = null;
  submittedUTC: Date = null;
  additionalProvisions: string = null;
  scopeOfWorkJobs: ScopeOfWorkJob[] = [];
  userAnswers: UserAnswer[] = [];
  rooms: Room[] = [];
  tasks: Task[] = [];

  static EstimatedBudgetOptions = [
    { id: 7, value: '> $300,000' },
    { id: 6, value: '$200,001 - $300,000' },
    { id: 5, value: '$150,001 - $200,000' },
    { id: 4, value: '$100,001 - $150,000' },
    { id: 3, value: '$75,001 - $100,000' },
    { id: 2, value: '$50,001 - $75,000' },
    { id: 1, value: '$25,001 - $50,000' },
    { id: 0, value: '$10,000 - $25,000' },
    { id: 8, value: 'Not sure' },
  ];
}

export enum ProjectStatus
{
  Submitted = 1,
  Accepted = 2,
  Declined = 3,
  InitialVisitScheduled = 4,
  AwaitingProposal = 5,
  ContractApprovalPending = 6,
  PreConstruction = 7,
  ActiveConstruction = 8,
  Punchlist = 9,
  FinalBilling =10,
  Warranty = 11,
  Legacy = 12,
  Incomplete = 13,
  Deleted = 14
}

  export class Room {
    constructor(
        public displayDocumentUrl: string,
        public documentNo: number,
        public numberOfDoors: number,
        public numberOfWindows: number,
        public projectNo: number,
        public roomDimensions: string,
        public roomNo: number,
        public roomText: string,
        public thumbnailUrl: string,
    ) { }
    static create() {
      return new Room(
        null, null, null, null, null,  null, null, null, null
      );
    }
  }
  
  export class RoomDimensions {
    constructor(
      public lengthFeet: number,
      public lengthInches: number,
      public widthFeet: number,
      public widthInches: number,
      public heightFeet: number,
      public heightInches: number,
    ) { }
      static create() {
        return new RoomDimensions(
           null, null, null, null, null, null
      );
    }
  }
  
  export class ScopeOfWorkJob {
    constructor(
      public jobNo: number,
      public projectNo: number,
      public jobText: string,
      public weeks: number,
      public days: number,
      public scopeOfWorkItems: ScopeOfWorkItem[],
    ) { }
    static create() {
      return new ScopeOfWorkJob(
         null, null, null, null, null, null
      );
  }

}export class ScopeOfWorkResponse extends ApiResponse {
  // this may have to be an array of SoW
  public scopeOfWork: ScopeOfWorkJob[];
}

export class ScopeOfWorkItem {
  constructor(
    public itemNo: number,
    public jobNo: number,
    public itemText: string,
    public isComplete: boolean,
  ) { }

  static create() {
    return new ScopeOfWorkItem(
      null, null, null, null
    );
  }
}
export class ProjectResponse extends ApiResponse {
  public project: Project;
}

export class ProjectsResponse extends ApiResponse {
  public projects: Project[];
}

export class RoomResponse extends ApiResponse {
  public room: Room;
}

export class InspirationsResponse extends ApiResponse {
  public inspirations: Inspiration[];
}

export class GetTasksResponse extends ApiResponse {
  public tasks: Task[];
}

export class Inspiration {
  constructor(
    public inspirationNo: number,
    public projectNo: number,
    public userNo: number,
    public userName: string,
    public inspirationName: string,
    public inspirationNote: string,
    public inspirationLink: string,
    public documentNo?: number,
    public thumbnailUrl?: string,
    public displayDocumentUrl?: string,
  ) { }
}

export class Contractor extends User {
  constructor(
    public specialties: string,
    public ein: string,
    public areaOfWorkDistance: number,
    public yearsOfExperience: number,
    public applicationSubmissionUTC: Date,
    public licenseNo: string,
    public otherTradeNames: string,
    public yearsInBusiness: number,
    public hasBusinessInsurance: boolean,
    public classifications: string,
    public website: string,
    public referenceName1: string,
    public referenceEmail1: string,
    public referenceName2: string,
    public referenceEmail2: string,
    public referenceName3: string,
    public referenceEmail3: string,
    public referenceEmailSent1: boolean,
    public referenceEmailSent2: boolean,
    public referenceEmailSent3: boolean,
    public contractorDocuments: ContractorDocument[],
    ) { super(0, '', 0, 1, '', '', '', '', true, false, '', '', '', '', null, [], false, null, null, null, null, null, '', '', [], [], [], ''); }

  static create() {
    return new Contractor('', '', 0, 0, null, '', '', 0, false, '', '', '', '', '', '', '', '', false, false, false,[]);
  }
}

export class Address {
  constructor(
    public addressNo: number,
    public isMailingAddress: boolean,
    public streetAddress: string,
    public city: string,
    public stateCode: string,
    public zipCode: string,
  ) {}
  static create() {
    return new Address(null, false, '', '', null, '' );
  }
}

export class ContractorDocument {
  constructor(
    public documentNo: number,
    public originalDocumentName: string,
    public expirationDate: Date,
    public downloadDocumentUrl: string,
    public thumbnailUrl: string,
  ) { }
  static create() {
    return new ContractorDocument(null, '', null, '', null);
  }
}

export class Note {
  constructor(
    public noteNo: number,
    public projectNo: number,
    public userNo: number,
    public userName: string,
    public noteText: string,
    public lastUpdatedUTC: Date,
  ) { }

  static create() {
    return new Note(null, 0, 0, '', '', new Date());
  }
}

export class NotesResponse extends ApiResponse {
  public notes: Note[];
}

export class ImportantDocument {
  constructor(
    public importantDocumentNo: number,
    public projectNo: number,
    public userNo: number,
    public userName: string,
    public documentNo: number,
    public documentName: string,
    public originalDocumentName: string,
    public documentTypeNo: number,
    public documentTypeName: string,
    public uploadedUTC: Date,
    public downloadDocumentUrl: string,
  ) { }

  static create() {
    return new ImportantDocument(null, 0, 0, '', 0, '', '', 0, '', new Date(), '');
  }
}

export class ImportantDocumentsResponse extends ApiResponse {
  public importantDocuments: ImportantDocument[];
}

export class Milestone {
  constructor(
    public milestoneNo: number,
    public projectNo: number,
    public milestoneName: string,
    public isComplete: boolean,
    public isVisit: boolean,
    public milestoneDate: Date,
    public paymentInformation: string,
  ) { }

  static create() {
    return new Milestone(null, 0, '', false, false, null, '');
  }
}

export class Visit {
  visitNo: number = null;
  visitName: string = '';
  firstDate: Date = null;
  firstTime: string = '';
  secondDate: Date = null;
  secondTime: string = '';
  thirdDate: Date = null;
  thirdTime: string = '';
  selectedDate: Date = null;
  selectedTime: string = '';
  isComplete: boolean = false; 
}

export class MilestonesResponse extends ApiResponse {
  public milestones: Milestone[];
  public visits: Visit[];
  public projectStatusNo: number;
}



export class Message {
  constructor(
    public messageNo: number | null,
    public projectNo: number,
    public userNo: number,
    public messageText: string,
    public messageTypeNo: number,
    public messageFlags: string,
    public messageTimeUTC: Date,
    public messageTimeString: string,
    public messageSender: string,
    public messageDocuments: MessageDocument[],
    public hideFlagDropdown: boolean = true
  ) { }

  static create() {
    return new Message(null, 0, 0, '', 0, null, new Date(), '', '', [], true);
  }
}
export class firbaseOBJ{
  public messages:Message;
  public projectN:string;
}
export class MessagesResponse extends ApiResponse {
  public messages: Message[];
  public reachedFirstMessage: boolean;
}

export class MessageDocument {
  messageDocumentNo: number | null;
  documentNo: number | null;
  messageNo: number;
  originalDocumentName: string;
  downloadDocumentUrl: string;
  thumbnailUrl: string;
}




export class Notification {
  constructor(
    public notificationNo: number,
    public projectNo: number,
    public projectName: string,
    public userNo: number,
    public notificationTypeNo: number,
    public notificationTypeText: string,
    public customText: string,
    public createdUTC: Date,
    public readUTC: Date,
    public destinationURL: string,
    public dueTimeUTC: Date,
    public isResolved: boolean,
  ) { };

  static create() {
    return new Notification(null, null, '',  null, null, '', '', null, null, '', null, null);
  }
}

export class FirebaseNotification {
  constructor(
    public isReceived: boolean,
    public notification: Notification
  ) { };
}

export class NotificationsResponse extends ApiResponse {
  public notifications: Notification[];
}

export class ImportantDocumentTypesResponse extends ApiResponse {
  public importantDocumentTypes: ImportantDocumentType[];
}

export class ImportantDocumentType {
  public documentTypeNo: number;
  public documentTypeName: string;
}
export class UserAnswer {
  answerNo: number;
  answerText: string;
  questionText: string;
  documentNo: number;
  documentUrl: string;
}

export class UserAnswersResponse extends ApiResponse {
  public userAnswersStructure: UserAnswerStructure;
}

export class UserAnswerStructure  {
  public userNo: number;
  public projectNo: number;
  public userAnswers: UserAnswer[];
}

export class Tree {
  constructor(
    public treeNo: number | null,
    public treeName: string,
    public firstQuestionNo: number | null,
    public treeStatusNo: number | null,
    public lastUpdatedUTC: Date,
    public treeQuestions: TreeQuestion[],
    public documentNo: number | null,
    public thumbnailUrl: string,
    public projectInqueries: number,
    public declinedProjects: number,
    public liveProjects: number,
    public completedProjects: number,
    public numberOfQuestions: number
  ) { };


  static create() {
    return new Tree(null, '', null, null, new Date(), [], null, '', 0, 0, 0, 0, 0)
  }
}

export class TreeQuestion {
  constructor(
    public questionNo: number,
    public questionTypeNo: number,
    public questionText: string,
    public toolTip: string,
    public treePossibleAnswers: TreePossibleAnswer[],
  ) { }
}

export class TreePossibleAnswer {
  constructor(
    public answerNo: number,
    public questionNo: number,
    public answerText: string,
    public nextQuestionNo: number | null,
  ) { };
}

export class TreeResponse extends ApiResponse {
  tree: Tree;
}

export class TreeListResponse extends ApiResponse {
  trees: Tree[];
}

export class TreeQuestionType {
  questionTypeNo: number;
  questionType: string;
}

export class TreeQuestionTypeResponse extends ApiResponse {
  treeQuestionTypes: TreeQuestionType[];
}

export class ContractorRequest {
  StartDate: Date;
  EndDate: Date;
  ServiceAreaNo: number;
  Speciality: string
}
export class ContractorResponse {
  contractorsCreated: number;
  contractorsCreatedEarlier: number;
  monthAbbreviation: string;
  year: number;
}


export class HomeownerRequest {
  StartDate: Date;
  EndDate: Date;
  ServiceAreaNo: number;
}
export class HomeownerResponse {
  homeownersCreated: number;
  homeownersCreatedEarlier: number;
  monthAbbreviation: string;
  year: number;
}

export class ProjectGraphRequest {
  StartDate: Date;
  EndDate: Date;
  ServiceAreaNo: number;
  ProjectTypeNo: number;
  ContractorNo: number;
}

export class ProjectGraphResponse {
  projectsCompleted: number;
  projectsSubmited: number;
  projectsSigned: number;
  year: number;
  monthAbbreviation: string;
}

export class ContractsSignedRequest {
  StartDate: Date;
  EndDate: Date;
  ServiceAreaNo: number;
  ProjectTypeNo: number;
  ContractorNo: number;
}
export class ContractsSignedResponse {
  year: number;
  monthAbbrev: string;
  treeName: string;
  numSigned: string;
}
export class AddressModel {
  serviceAreaNo: number;
  serviceAreaName: string;
  serviceAreaZipCode: string;
}
export class ContracterModel {
  userNo: number;
}
export class HomeOwnerModel {
  userNo: number;
}
export class ProjectTypeModel {
  treeNo: number;
  treeName: string;
}

export class ProjectFilter {
  addressList: AddressModel[] = [];
  homeOwnerList: HomeOwnerModel[] = [];
  projectTypeList: ProjectTypeModel[] = [];
  contractorList: ContracterModel[] = [];
}
export class ProjectStatisticsRequest {
  HomeOwnerNo: number;
  ServiceAreaNo: number;
  ProjectTypeNo: number;
  ContractorNo: number;
}

export class ProjectStatisticsResponse {
  projectStatusDescription: string;
  projectStatusNo: number;
  numProjects: number;
  percentProjects: number;
  avgDays: number;
  percentActive: number;
}

export enum TaskType
{
  ScheduleInitialVisitH = 1,
  ScheduleInitialVisitC = 2,
  CompleteInitialVisit = 3,
  SubmitProposal = 4,
  SubmitContract = 5,
  SignContractC = 6,
  SignContractH = 7,
  StartConstruction = 8,
  SubmitInvoice = 9,
  ReviewInvoice = 10,
  StartPunchlist = 11,
  StartFinalBilling = 12,
  StartWarranty = 13
}

export class Task {
  taskNo: number | null;
  projectNo: number | null;
  taskCategoryNo: number | null;
  roleNo: number | null;
  taskCategoryText: string | null;
  completedUTC: Date | null;
  createdUTC: Date | null;
  canceledUTC: Date | null;
  visit: Visit;
  extraInfo: string;
  currentStatusNo: number | null;
  nextStatusNo: number | null;
  project: Project;
  ownerName: string;

  constructor(options: {
    taskNo?: number,
    projectNo?: number,
    taskCategoryNo?: number,
    roleNo?: number,
    taskCategoryText?: string,
    completedUTC?: Date,
    createdUTC?: Date,
    canceledUTC?: Date,
    visit?: Visit,
    extraInfo?: string,
    currentStatusNo?: number,
    nextStatusNo?: number,
    project?: Project
  } = {}) {
    this.taskNo = options.taskNo || null;
    this.projectNo = options.projectNo || null;
    this.taskCategoryNo = options.taskCategoryNo || null;
    this.roleNo = options.roleNo || null;
    this.taskCategoryText = options.taskCategoryText || null;
    this.completedUTC = options.completedUTC || null;
    this.createdUTC = options.createdUTC || null;
    this.canceledUTC = options.canceledUTC || null;
    this.visit = options.visit || new Visit();
    this.extraInfo = options.extraInfo || '';
    this.currentStatusNo = options.currentStatusNo || null;
    this.nextStatusNo = options.nextStatusNo || null;
    this.project = options.project || new Project();
  }
}
export class BudgetingRequest {
  StartDate: Date;
  EndDate: Date;
  ServiceAreaNo: number;
  ContractorNo: number;
  BudgetMin: number;
  BudgetMax: number;
}
export class BudgetingResponse {
  budgetingStatistics: BudgetingStatistics[];
  budgetingPercents: BudgetingPercents;
}
export class BudgetingStatistics {
  ProjectType: string;
  ProjectCount: number;
  ProjectAvgCost: number;
  ProjectMinCost: number;
  ProjectMaxCost: number;
  AvgOpenMonth: number;
  AvgCloseMonth: number;
}
export class BudgetingPercents {
  percentDeclined: number;
  percentAccepted: number;
  percentPending: number;
  percentConstruction: number;
  percentCompleted: number;
}

export class ProjectStagesRequest {
  StartDate: Date;
  EndDate: Date;
  ServiceAreaNo: number;
  ContractorNo: number;
  BudgetMin: number;
  BudgetMax: number;
}

export class GraphObject
{
  name: string;
  value: number;
  constructor(name:string,value:number)
  {
    this.name = name;
    this.value = value;
  }
}

export class ProjectStages {
  constructor (
    public CategoryType: string,
    public CreatedAcceptedAvg: number,
    public InitialVisitScheduledAvg: number,
    public AwaitingProposalAvg: number,
    public ContractPendingAvg: number,
    public PreConstructionAvg: number,
    public ActiveConstructionAvg: number,
    public PunchlistAvg: number,
    public FinalBillingAvg: number,
    public WarrantyAvg: number,
    public LegacyAvg: number
  ) {};
}

export class ProjectStagesResponse extends ApiResponse {
  public projectStages: ProjectStages[];
}

export class ContractorInfo {
  constructor (
    public userNo: number,
    public firstName: string,
    public lastName: string
  ) {};
}

export class ContractorListResponse {
  public contractors: ContractorInfo[];
}

export class ProjectYear {
  public year: number;
}

export class ProjectYearsResponse {
  public years: ProjectYear[];
}

export class KPIMonthYearWiseStats
{
  currentMonthProjectCount: number;
  currentYearProjectCount: number;
  lastMonthProjectCount: number;
  lastYearProjectCount: number;
  monthMinBudget: number; 
  monthMaxBudget: number;
  yearMinBudget: number; 
  yearMaxBudget: number;
  completedProjectsCount: number;
  liveProjectsCount: number;
  liveHomeOwnersCount: number;
  liveContractorsCount: number;
}

export class KPIActiveProjectsStats
{
  currentMonthContractorCount: number;
  currentYearContractorCount: number;
  currentMonthHomeOwnerCount: number;
  currentYearHomeOwnerCount: number;
  lastMonthContractorCount: number;
  lastYearContractorCount: number;
  lastMonthHomeOwnerCount: number;
  lastYearHomeOwnerCount: number;
}

export class KPIDashBoardStats
{
  kpiActiveProjects: KPIActiveProjectsStats;
  kpiMonthYearStats: KPIMonthYearWiseStats;
  constructor()
  {
    this.kpiActiveProjects = new KPIActiveProjectsStats();
    this.kpiMonthYearStats = new KPIMonthYearWiseStats()
  }
}
export class UserList {
  public userNo: number;
  public descriptor: string;
}

export class UserListResponse extends ApiResponse {
  public users: UserList[];
}
