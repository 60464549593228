//this is the template of the page once the user has logged in: header and main menu. The space on the right will be filled in with sub-pages.
import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { MenuItems, Menu, ChildrenItems } from '../../shared/menu-items/menu-items';
import { MessageBoxComponent } from '../../shared/message-box/message-box.component';
import { WebService, ApiResponse, FirebaseNotification,  } from '../../shared/web-service/web.service';
import { Formatter, SessionSave } from '../../shared/utilities/utilities';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { NavigationEnd, Router } from '@angular/router';
import { AngularFireDatabase } from "@angular/fire/database";

import { ToastrService } from 'ngx-toastr'
import { take } from 'rxjs/operators';

declare var buildNumber: string; // This tells the compiler that it's a global variable coming from another file. In our case, it's coming from config.js.

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('notificationBottom', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('mobileHeaderNavRight', [
      state('nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('nav-on',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('nav-off <=> nav-on', [
        animate('400ms ease-in-out')
      ])
    ]),
  ]
})
export class AdminComponent implements OnInit {
  @ViewChild('modalChangePwd') modalChangePwd;
  @ViewChild('modalChangeUserInfo') modalChangeUserInfo;
  @ViewChild('modalHelpAbout') modalHelpAbout;
  @ViewChild('msgBox', { static: true }) msgBox: MessageBoxComponent;
  @ViewChild('mainMenu') mainMenu: MatSidenav;
  @ViewChild('subMenu') subMenu: MatDrawer;

  public static staticInstance: AdminComponent;

  public navType: string;
  public themeLayout: string;
  public verticalPlacement: string;
  public verticalLayout: string;
  public pcodedDeviceType: string;
  public verticalNavType: string;
  public verticalEffect: string;
  public vnavigationView: string;
  public freamType: string;
  public sidebarImg: string;
  public sidebarImgType: string;
  public layoutType: string;

  public headerTheme: string;
  public pcodedHeaderPosition: string;

  public liveNotification: string;
  public liveNotificationClass: string;

  public profileNotification: string;
  public profileNotificationClass: string;

  public navRight: string;
  public windowWidth: number;

  public toggleOn: boolean;
  public navBarTheme: string;
  public activeItemTheme: string;
  public pcodedSidebarPosition: string;

  public menuTitleTheme: string;
  public dropDownIcon: string;
  public subItemIcon: string;

  public headerFixedMargin: string;
  public sidebarFixedHeight: string;
  public itemBorderStyle: string;
  public subItemBorder: boolean;
  public itemBorder: boolean;
  public employeeName: string;

  public config: any;

  newEmail: string;
  infoErrorMsg: string;
  showInfoErrorMsg: boolean = false;
  passwordOld: string;
  passwordNew: string;
  passwordErrorMsg: string;
  showPasswordErrorMsg: boolean = false;
  tokenExpirationSeconds: number;
  revealPwd1: boolean = false;
  revealPwd2: boolean = false;
  revealPwd3: boolean = false;
  rowDataNavigationPage: object[] = [];
  lastPageClickeOn: string;
  parentMenuName: string;
  artificiallyHighlightedMenuItem: boolean;
  projectName: string = sessionStorage.getItem("projectName"); //We save this in sessionStorage in case the page gets refreshed.
  userStatusNo: number = Number.parseInt(sessionStorage.userStatusNo);
  userNo: number; // userNo of current user
  projectNo: number ;
  buildNumberFromConfigFile: string = buildNumber;
  headerHeight: number = 100; // used to determine header height and the height of the body

  browserRegistered: boolean = (sessionStorage.browserRegistered == "1");
  selectedProject: string;
  showSubMenu: boolean = false;
  mainMenuItems: Menu[];
  subMenuItems: ChildrenItems[];
 isReceived:boolean=false;
 isUpdatedbycurrentuser:boolean=false;
  notificationCount: number; // number of unread notifications for current user

  constructor(public menuItems: MenuItems, private webService: WebService, private router: Router, private firebaseDB: AngularFireDatabase,private toastr: ToastrService) {
    AdminComponent.staticInstance = this;

    // This will go through all the items in the main menu on the left side of the screen and remove any items that the current user doesn't have permission to view. The MainMenuItems array is the top nodes. Each node has a rolesCanView property. Then those might have children of type ChildrenItems that also have a rolesCanView property. The rolesCanView property is a comma-delim list of roles that can access the page. We get an array of roles that the current user has from sessionStoriage. We go through each MainMenuItems node and each ChildrenItems node, and remove any item from the array where the roles of the current user aren't found in the list of roles for the page. For the arrays, we start at the end and go backwards, so things don't get messed up if we remove an item from the array.
    this.userNo = parseInt(sessionStorage.getItem("userNo"));
    this.projectNo=parseInt(sessionStorage.getItem("projectNo"));
    let userRoles: string[] = JSON.parse(sessionStorage.authorityRoles ?? "[]");
    let tempMenuItems: Menu[] = menuItems.getAllMain();
    for (let i = tempMenuItems[0].main.length - 1; i >= 0; i--) {
      // check all the top-level nodes
      if (!this.foundRoleMatch(userRoles, tempMenuItems[0].main[i].rolesCanView)) {
        tempMenuItems[0].main.splice(i, 1);
      }
      else if (tempMenuItems[0].main[i].children) {
        for (let x = tempMenuItems[0].main[i].children.length - 1; x >= 0; x--) {
          // check all the children nodes
          if (!this.foundRoleMatch(userRoles, tempMenuItems[0].main[i].children[x].rolesCanView)) {
            tempMenuItems[0].main[i].children.splice(x, 1);
          }
        }
      }
    }

    // There is HTML that is bound to mainMenuItems and subMenuItems to create the menu
    this.mainMenuItems = tempMenuItems;
    this.selectedProject = sessionStorage.selectedProject;

    if (sessionStorage.tokenExpirationSeconds) {
      this.tokenExpirationSeconds = parseInt(sessionStorage.tokenExpirationSeconds);
      setInterval(() => {
        if (sessionStorage.lastWebApiCall) {
          let numSecondsSinceLastApiCall = (new Date().getTime() - parseInt(sessionStorage.lastWebApiCall)) / 1000;
          if (numSecondsSinceLastApiCall > this.tokenExpirationSeconds) {
            console.log("Auto log out user because it's been too long (past token expiration) since they've done anything that has used an API call.");
            sessionStorage.clear();
            sessionStorage.logoutReason = "AuthorizationError";
            window.location.assign(document.getElementsByTagName('base')[0].href + "login");
          }
        }
      }, 60000);
    }

    this.employeeName = sessionStorage.employeeName;
    if (!this.employeeName || this.employeeName.length === 0 || !sessionStorage.validationToken || sessionStorage.validationToken.length === 0)
      this.onLogout();

    this.navType = 'st2';
    this.themeLayout = 'vertical';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.pcodedDeviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.vnavigationView = 'view1';
    this.freamType = 'theme1';
    this.sidebarImg = 'false';
    this.sidebarImgType = 'img1';
    this.layoutType = 'light';

    this.pcodedHeaderPosition = 'fixed';

    this.liveNotification = 'an-off';
    this.profileNotification = 'an-off';

    this.navRight = 'nav-on';

    this.windowWidth = window.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    this.toggleOn = true;
    this.navBarTheme = 'theme1';
    //this.activeItemTheme = 'theme10'; 
    this.pcodedSidebarPosition = 'fixed';
    this.menuTitleTheme = 'theme6';
    //this.dropDownIcon = 'style3';
    this.subItemIcon = 'style7';

    this.headerFixedMargin = '56px';
    this.sidebarFixedHeight = 'calc(100vh - 56px)';
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.itemBorder = true;

    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);

    // dark
    /*this.setLayoutType('dark');*/

    // light-dark
    /*this.setLayoutType('dark');
    this.setNavBarTheme('themelight1');*/

    // dark-light
    /*this.setNavBarTheme('theme1');*/

    // side-bar image
    /*this.setLayoutType('img');*/
    //???These are ones customized by Gilmer. See setLayoutType()
    this.layoutType = 'light';
    this.sidebarImg = 'false';
    this.navBarTheme = 'theme1';
    this.menuTitleTheme = 'theme1';
    document.querySelector('body').classList.remove('dark');
    this.headerTheme = 'theme1';
    this.dropDownIcon = 'style1';
    this.activeItemTheme = 'theme1'//this is for the color of the selected menu item.

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //If we are currently on one of the subpages, then show the subpage menu items.
        this.showSubMenu = false;
        this.subMenuItems = [];
        this.mainMenuItems[0].main.forEach(elem => {
          if (elem.children && window.location.pathname.indexOf(elem.state) != -1) {
            let children = elem.children.filter((child) => { return window.location.pathname.indexOf(child.state) != -1; });
            this.showSubMenu = children.length == 1;
            this.subMenuItems = elem.children;
          }
        });
      }
    })
  }

  //returns true if any of the roles in userRoles (comma-delim list of all the roles for the user) is found in rolesForPage (comma-delim list of the roles that can access a page)
  foundRoleMatch(userRoles: string[], rolesForPage: string): boolean {
    let rval: boolean = false;
    rolesForPage = "," + rolesForPage + ",";
    userRoles.forEach(elem => {
      if (rolesForPage.indexOf("," + elem + ",") != -1)
        rval = true;
    });
    return rval;
  }

  ngOnInit() {
    $("*[cdkscrollable]").css("overflow-x", "hidden");//this is to get rid of a little horiz scrollbar that sometimes appears in the main menu. I couldn't put it in the HTML because the div wasn't in the HTML. But the div happened to have the cdkscrollable attribute on it.

    if(sessionStorage.email == '' || sessionStorage.email == undefined) {
      console.log("Logging out user in ngOnInit() because there is stuff missing from sessionStorage");
      window.location.assign(document.getElementsByTagName('base')[0].href + "login");
    }
    this.notificationCount = 0;
   this.countNotifications(); // update the number of unread notifications
    this.setBackgroundPattern('pattern1');

    // The msgBox of this component can be used anywhere. Use setTimeout to make sure the object has been created.
    setTimeout(() => {
      MessageBoxComponent.staticInstance = this.msgBox;
    }, 100);

    this.firebaseDB
    .object(
      `NotificationMonitor/${this.userNo}`
    )
    .valueChanges()
    .subscribe((data: any) => {  
      if (data?.notification) {
        this.countNotifications();
        if(!(window.location.href.toLowerCase().includes("notifications") || (window.location.href.toLowerCase().includes("messaging") && Number(sessionStorage.getItem("projectNo")) == data.notification.ProjectNo)) 
          && !data.isReceived)
        {
          this.showToasterInfo(data.notification?.CustomText, data.notification?.ProjectNo?.toString(), data.notification?.DestinationURL);
          this.firebaseDB
          .object(
            `NotificationMonitor/${this.userNo}`
          )
          .update({
            isReceived: true
          });
        }
      }
    })
    

  }

  showToasterInfo(msgText: string, projectNo: string, destinationURL: string){
    this.toastr.info(msgText, this.getQueryStringValueByName(destinationURL, "project")?.replaceAll("_", " ")).onTap
    .pipe(take(1))
    .subscribe(() => this.toasterClickedHandler(projectNo, destinationURL));
  }

  toasterClickedHandler(projectNo :string, destinationURL:string){
    if(destinationURL == null || destinationURL == undefined || destinationURL == "")
      return;
    sessionStorage.removeItem("userNoHomeowner");
    sessionStorage.removeItem("userNoContractor");
    sessionStorage.removeItem("userNoAdmin");
    sessionStorage.setItem("projectNo", projectNo + '');
    sessionStorage.setItem("threadNo", this.getQueryStringValueByName(destinationURL, "thread"))
    sessionStorage.setItem("projectName", this.getQueryStringValueByName(destinationURL, "project")?.replaceAll("_", " "))
    var route: String = "/" + destinationURL.split('?')[0]; // structure the route
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([route]) // navigate to the new page
    );
  }

  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    let reSizeFlag = true;
    if (this.pcodedDeviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.pcodedDeviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setHeaderAttributes(windowWidth) {
    if (windowWidth < 992) {
      this.navRight = 'nav-off';
    } else {
      this.navRight = 'nav-on';
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.pcodedDeviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else if (windowWidth < 768) {
      this.pcodedDeviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
      this.headerHeight = 60;
    } else {
      this.pcodedDeviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleHeaderNavRight() {
    this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
  }

  toggleLiveNotification() {
    this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'active' : '';
  }

  toggleProfileNotification() {
    this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'active' : '';
  }

  notificationOutsideClick(ele: string) {
    if (ele === 'live' && this.liveNotification === 'an-animate') {
      this.toggleLiveNotification();
    } else if (ele === 'profile' && this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }
  }

  toggleOpened() {
    if (this.windowWidth < 992) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
      if (this.navRight === 'nav-on') {
        this.toggleHeaderNavRight();
      }
    }
    this.mainMenu.toggle();
    if (this.showSubMenu) {
      this.subMenu.toggle();
    }
  }

  onClickedOutsideSidebar(e: Event) {
    if ((this.windowWidth < 992 && this.toggleOn && this.verticalNavType !== 'offcanvas') || this.verticalEffect === 'overlay') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      this.sidebarImg = 'false';
    } else {
      this.menuTitleTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.sidebarImg = 'false';
    }
  }

  setLayoutType(type: string) {
    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme1';
      this.sidebarImg = 'false';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body').classList.add('dark');
    } else if (type === 'light') {
      this.sidebarImg = 'false';
      this.headerTheme = 'themelight5';
      this.navBarTheme = 'themelight1';
      this.menuTitleTheme = 'theme1';
      document.querySelector('body').classList.remove('dark');
    } else if (type === 'img') {
      this.sidebarImg = 'true';
      this.headerTheme = 'theme1';
      this.navBarTheme = 'theme1';
      this.menuTitleTheme = 'theme6';
      document.querySelector('body').classList.remove('dark');
    }
  }

  setBackgroundPattern(pattern: string) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
  }

  onShowAbout() {
    this.modalHelpAbout.show();
    setTimeout(function () { $("#aboutModalOkButton").focus() }, 300); //set focus on button so if user clicks Enter, it will close. We have to use setTimeout to set the focus because you can't set focus until it appears, which takes a moment.
  }

  onChangePwd() {
    this.passwordOld = "";
    this.passwordNew = "";
    this.showPasswordErrorMsg = false;
    this.modalChangePwd.show();
    setTimeout(function () { $("#passwordOld").focus() }, 300); //set focus on first field
  }

  finishChangePwd() {
    if (this.passwordOld.length === 0 || this.passwordNew.length === 0) {
      this.passwordErrorMsg = "Please enter your old password and a new password.";
      this.showPasswordErrorMsg = true;
      return;
    }

    this.showPasswordErrorMsg = false;

    this.webService.users_ChangePassword(sessionStorage.email, this.passwordOld, this.passwordNew).subscribe(
      apiResponse => {
        if (apiResponse.errorCode === 0) {
          //change pwd worked
          this.modalChangePwd.hide();
          this.msgBox.show("Success", "Your password has been changed.", false);
          this.revealPwd1 = false;
          this.revealPwd2 = false;
          this.revealPwd3 = false;
        }
        else {
          //server says something didn't work, so show message from server.
          this.passwordErrorMsg = apiResponse.errorMessage;
          this.showPasswordErrorMsg = true;
        }
      }
    );
  }

  onLogout() {
    this.webService.authentication_LogUserLogout();
    sessionStorage.clear();
    //we redirect the browser URL to the login page. We do it after a 10 ms pause to allow the API call above to get going.
    //we can't just do window.location.pathname = "login" because it's possible our URL has a virtual folder we need to worry about.
    setTimeout(() => window.location.assign(document.getElementsByTagName('base')[0].href + "login"), 10);
  }

  onForgetMe() {
    this.msgBox.show("Confirm", `Are you sure you want to remove your registration on this browser? If you do this, you will need to go through two-step authentication the next time you log in.`, true,
      () => {
        this.webService.authentication_DeleteUserAuth(localStorage.browserGuid).subscribe();
        this.browserRegistered = false;
      });
  }

  //This is the event when you click on a page to view something in the system, and it's a top-level menu item. 
  onMainMenuItemClicked(pageName: string, clickEvent) {
    if (this.pcodedDeviceType != 'desktop') {
      if (this.mainMenu)
        this.mainMenu.close();
      if (this.subMenu)
        this.subMenu.close();
    }
  }

  selectProject(projName) {
    this.showSubMenu = true;
    this.selectedProject = projName;
    sessionStorage.setItem("projectName", projName);
    sessionStorage.selectedProject = this.selectedProject;
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  // get the number of unread notifications for current user to display on side menu
  countNotifications() { 
    
    this.webService.notifications_GetNotificationsByUser(this.userNo).subscribe(
      apiResponse => {
        if (ApiResponse.checkStatus(apiResponse)) {
          var notifications = 0;
          for (const notification of apiResponse.notifications) {
            if (notification.readUTC == null) {
              notifications++;
            }
          }
          this.notificationCount = notifications;
          if(!window.location.href.toLowerCase().includes('notifications') && this.notificationCount>0)
          { let notification=apiResponse.notifications[0];                   
      }
    }
      }
    );
  }

  //on click of submenu navigation will be hide 
  onSubMenuItemClicked() {
    if (this.pcodedDeviceType != 'desktop') {
      if (this.mainMenu)
        this.mainMenu.close();
      if (this.subMenu)
        this.subMenu.close();
    }
  }

  getQueryStringValueByName(destinationUrl, name) {
    var queryStringFromStartOfValue = destinationUrl?.split(name + '=')[1];
    return (typeof queryStringFromStartOfValue === 'string' ? queryStringFromStartOfValue.split('&')[0] : null);
  }
}
