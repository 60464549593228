//This is a simple class that gets shared between the interceptor and the spinner component, so the interceptor can tell the spinner if a web service call is going.
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading: boolean = false;

  constructor() {}
}

