//???This spinner came with the template but didn't really seem to work. I (Gilmer) changed the HTML and CSS to be this spinner: https://codepen.io/WebSonata/pen/bRaONB. I then had to pass the LoaderService into this class. I don't think we need anything to do with Spinkit, which is the spinner that came with the template.
import { Component, Input, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LoaderService } from '../web-service/loader.service';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: [
        './spinner.component.scss',
    ],
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
    public isSpinnerVisible = true;
    constructor(public loaderService: LoaderService, private router: Router, @Inject(DOCUMENT) private document: Document) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible = true;
            } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible = false;
            }
        }, () => {
            this.isSpinnerVisible = false;
        });

        setInterval(() => {  }, 1000);//Kludge. I was trying to figure out why (when the browser is a mobile size) when you navigate from the Projects page to the Project Overview page, it would often sit there churning for many seconds before showing the PO page. It appears the problem is with navigating to the page, not in the API calls or rendering the HTML. So I put a timer interval function here to write out the value of isSpinnerVisible to the log once a second. When I did that, it stopped taking so long to navigate. So then I removed writing to the log, and left a timer function that does nothing, and it sill seemed to help.
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
    }
}
