<div class="card {{ fullCard }} {{ cardLoad }}" [@cardClose]="cardClose" [ngClass]="cardClass">
  <div class="card-header" *ngIf="title">
    <h5>{{ title }}</h5>
    <span *ngIf="!classHeader">{{ headerContent }}</span>
    <span *ngIf="classHeader">
      <ng-content select=".code-header"></ng-content>
    </span>
  </div>

  <div [@cardToggle]="cardToggle" class="card-block-hid">
    <div class="card-block" [ngClass]="blockClass">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="card-loader" *ngIf="loadCard"><i class="fa fa-refresh rotate-refresh"></i></div>
</div>
