<nav class="d-flex justify-content-between align-items-center main-nav w-100">
  <a class="mobile-menu hamburger me-0" id="mobile-collapse" href="javascript:" style="width:250px" (click)="toggleOpened()" [exclude]="'#main_navbar'"
     (clickOutside)="onClickedOutsideSidebar($event)">
    <i class="ti-menu" style="font-size: {{headerHeight / 4}}px"></i>
  </a>
  <a href="https://buildsage.com/" onclick="setTarget(this)" title="Go to BuildSAGE.com">
    <img class="py-1" style="height: {{headerHeight}}px" src="assets/images/BuildSage_logo.jpg" alt="Theme-Logo" />
  </a>
  <div class="navbar-container d-flex flex-column align-items-end me-1 me-md-3" style="width:250px; position: relative;">
    <ul class="nav-right d-flex justify-content-end" id="user-nav">
      <li (clickOutside)="notificationOutsideClick('profile')" class="user-profile header-notification d-flex justify-content-end"
          [ngClass]="profileNotificationClass" (click)="toggleProfileNotification()">
        <a class="user-anchor mx-0 d-flex justify-content-end align-items-center" href="javascript:">
          <span class="user-name me-2" style="text-align: right; font-size: {{headerHeight / 4}}px">{{employeeName}}</span>
          <i class="ti-angle-down"></i>
        </a>
      </li>
    </ul>
    <ul [@notificationBottom]="profileNotification" id="user-menu" class="show-notification profile-notification user-menu d-flex flex-column flex-start">
      <li class="style-about" style="width: 200px;">
        <a href="javascript:void(0);" (click)="onShowAbout()" class="no-underline">
          <i class="ti-info-alt"></i> About&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </a>
      </li>
      <li class="style-about">
        <a href="javascript:void(0);" (click)="onChangePwd()" class="no-underline">
          <i class="ti-key"></i> Change Password
        </a>
      </li>
      <li [hidden]="!browserRegistered" class="style-about" style="width: 250px;">
        <a href="javascript:void(0);" (click)="onForgetMe()" class="no-underline">
          <i class="ti-unlock"></i> Forget me on this Device
        </a>
      </li>
      <li class="style-about">
        <a href="javascript:void(0);" (click)="onLogout()" class="no-underline">
          <i class="ti-back-left"></i> Log Out&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </a>
      </li>
    </ul>
  </div>
</nav>

<mat-sidenav-container autosize style="height: calc(100vh - {{headerHeight}}px)">
  <mat-sidenav #mainMenu mode="side" [ngStyle]="{'min-width': !showSubMenu ? '150px' : '73px'}" style="z-index: 0;" [opened]="pcodedDeviceType=='desktop'?true:false" disableClose>
    <span *ngFor="let asideItems of mainMenuItems" class="d-color">
      <ul [routerLinkActive]="['active']"
          class="sidenav-item p-0 d-flex align-items-center"
          [attr.item-border]="itemBorder"
          [attr.item-border-style]="itemBorderStyle"
          [attr.subitem-border]="subItemBorder"
          *ngFor="let navItem of asideItems.main"
          group="{{navItem.state}}">
        <li class="d-flex align-items-center" *ngIf="navItem.type === 'link'" group="{{navItem.state}}">
          <a [routerLink]="['/', navItem.state]" style="position: relative;" target="{{navItem.target ? '_blank' : '_self'}}"
             (click)="onMainMenuItemClicked(navItem.name, $event)" title="{{navItem.tooltip}}">
            <span class="sidenav-item-micon ms-3" [style.margin-left]="showSubMenu ? '1.7rem !important' : ''"><i class="bx {{navItem.badge.type}}-{{navItem.badge.value}}"></i></span>
            <span class="sidenav-item-mtext mx-2" [ngClass]="showSubMenu ? 'move-labels' : ''"  >{{ navItem.name}}</span>
            <span class="notification-count ms-0" [ngClass]="showSubMenu ? 'notification-tiny' : ''" *ngIf="navItem.name == 'Notifications' && notificationCount >= 1">{{notificationCount}}</span>
            <span class="sidenav-item-mcaret"></span>
          </a>
        </li>
      </ul>
    </span>
  </mat-sidenav>
  <mat-sidenav-content style="height: calc(100vh - {{headerHeight}});">
    <mat-drawer-container style="height: 100%;">
      <mat-drawer #subMenu mode="side" [ngStyle]="{'z-index': (pcodedDeviceType == 'desktop' || pcodedDeviceType == 'tablet') ? 0 : ''}" [opened]="pcodedDeviceType=='desktop'?true:false" *ngIf="showSubMenu">
        <span class="d-color">
          <ul [routerLinkActive]="['active']" class="sidenav-item" [attr.item-border]="itemBorder"
              [attr.item-border-style]="itemBorderStyle" [attr.subitem-border]="subItemBorder"
              *ngFor="let subItem of subMenuItems" group="{{subItem.state}}">
            <li *ngIf="subItem.type === 'link'" group="{{subItem.state}}">
              <!--TODO possibly fix route (if more than projects can have subpages)-->
              <a [routerLink]="['/projects', subItem.state]" target="{{subItem.target ? '_blank' : '_self'}}"
                 (click)="onSubMenuItemClicked()" title="{{subItem.tooltip}}">
                <span class="sidenav-item-micon">
                  <i class="bx {{subItem.badge.type}}-{{subItem.badge.value}}"></i>
                </span>
                <span class="sidenav-item-mtext">{{ subItem.name }}</span>
                <span class="sidenav-item-mcaret"></span>
              </a>
            </li>
          </ul>
        </span>
      </mat-drawer>
      <mat-drawer-content>
        <div class="main-body">
          <div id="pageWrapper" class="page-wrapper user-body">            
            <router-outlet>
              <app-title #appTitle></app-title>
              <app-spinner></app-spinner>
            </router-outlet>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-modal-basic #modalHelpAbout>
  <div class="app-modal-header modal-padding">
    <h6 class="modal-title" style="font-style: italic;">About BuildSAGE</h6>
  </div>
  <div class="app-modal-body modal-padding">
    <table style="width:100%">
      <tr>
        <td style="padding-bottom: 15px; font-style: italic;"><a onclick="setTarget(this)" href="https://buildsage.com">https://buildsage.com</a></td>
      </tr>
      <tr>
        <td style="padding-bottom: 15px; font-style: italic;">Build Version: {{buildNumberFromConfigFile}}</td>
      </tr>
      <tr>
        <td style="padding-bottom: 15px; font-style: italic; white-space: pre-wrap">&copy; {{getCurrentYear()}}, BuildSAGE, LLC - ALL RIGHTS RESERVED</td>
      </tr>
      <tr>
        <td style="white-space: pre-wrap; padding-bottom: 15px; font-style: italic;">Unauthorized access to this system is prohibited by law - Title 18 U.S. Code Section 1030</td>
      </tr>
      <tr>
        <td style="font-style: italic;">Contact us at <a href="mailto:hello@buildsage.com">hello@buildsage.com</a></td>
      </tr>
    </table>
  </div>
  <div class="app-modal-footer modal-padding">
    <div class="button-group-align-right">
      <button type="button" class="btn btn-primary" id="aboutModalOkButton"
              (click)="modalHelpAbout.hide()">
        OK
      </button>
    </div>
  </div>
</app-modal-basic>

<app-modal-basic #modalChangePwd>
  <div class="app-modal-header modal-padding">
    <h6 class="modal-title">Change password for {{employeeName}}</h6>
  </div>
  <div class="app-modal-body modal-padding">
    <table style="width:100%">
      <tr [hidden]="!showPasswordErrorMsg">
        <td colspan="2" style="color: red;">
          {{passwordErrorMsg}}
        </td>
      </tr>
      <tr>
        <td>Old Password</td>
        <td style="position:relative">
          <input [type]="revealPwd1 ? 'text' : 'password'" [(ngModel)]="passwordOld" maxlength="45" class="form-control"
            (keydown.enter)="finishChangePwd()" id="passwordOld">
          <i class="ti-eye"
            style="transform: scale(2); position:absolute; right:11px; top:10.5px; cursor: pointer; z-index:9999"
            (click)="revealPwd1 = !revealPwd1" title="Show/hide password"></i>
        </td>
      </tr>
      <tr>
        <td>New password</td>
        <td style="position:relative">
          <input [type]="revealPwd2 ? 'text' : 'password'" [(ngModel)]="passwordNew" maxlength="45" class="form-control"
            (keydown.enter)="finishChangePwd()">
          <i class="ti-eye"
            style="transform: scale(2); position:absolute; right:11px; top:10.5px; cursor: pointer; z-index:9999"
            (click)="revealPwd2 = !revealPwd2" title="Show/hide password"></i>
        </td>
      </tr>
    </table>
  </div>
  <div class="app-modal-footer modal-padding">
    <div class="button-group-align-right">
      <button type="button" class="btn btn-primary" (click)="finishChangePwd()">Save</button>
      &nbsp;&nbsp;&nbsp;
      <button type="button" class="btn btn-cancel"
              (click)="modalChangePwd.hide(); revealPwd1 = false; revealPwd2 = false; revealPwd3 = false;">
        Cancel
      </button>
    </div>
  </div>
</app-modal-basic>

<message-box #msgBox></message-box>
