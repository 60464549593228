//helper functions to save stuff in sessionStorage and retrieve it later
export class SessionSave {
  public static readonly receivingAccountsKey: string = "ReceivingAccounts";
  public static readonly defaultDesignationKey: string = "defaultDesignationKey";
  public static readonly pledgesFilterKey: string = "PledgesFilter";
  public static readonly reportsGivingFilterKey: string = "ReportsGivingFilter";
  public static readonly reportsDevFilterKey: string = "ReportsDevFilter";
  public static readonly reportsPledgesFilterKey: string = "ReportsPledgesFilter";
  public static readonly depositsFilterKey: string = "DepositsFilter";

  //This will save an object in sessionStorage. 
  static save(key: string, data: object) {
    sessionStorage[key] = JSON.stringify(data);
  }
  //This function will get an object out of sessionStorage and (if it exists) copy all of its properties to the "data" parameter passed in. If nothing is found in sessionStorage, do nothing. The "data" object should already exist before being passed in.
  static get(key: string, data: object) {
    const str: string = sessionStorage[key];

    if (str && str != 'null' && str != 'undefined') {
      const newData: object = JSON.parse(str);
      for (var prop in newData)
        data[prop] = newData[prop];
    }
    return data;
  }

  //This function will get an object out of sessionStorage and (if it exists) copy all of its properties to the "data" parameter passed in. It's designed for objects that have filter data. If nothing is found in sessionStorage, initalize the properties of the "data" param: it's assumed that the filter object might have properties for toDate and fromDate, which are initialized to today (toDate) and a month ago (fromDate). All other properties are set to blank. The "data" object should already exist with all its properties before being passed in.
  static filterGet(key: string, data: object, monthsBackEndDateDefault: number = 1) {
    const str: string = sessionStorage[key];

    if (str && str != 'null' && str != 'undefined') {
      const newData: object = JSON.parse(str);
      for (var prop in data)
        data[prop] = newData[prop];
      for (var prop in newData)
        data[prop] = newData[prop];
    }
    else {
      let dateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
      for (var prop in data) {
        if (prop == "toDate") {
          data[prop] = new Date().toLocaleString("en-US", dateFormatOptions);
        }
        else if (prop == "fromDate") {
          var d = new Date();
          d.setMonth(d.getMonth() - monthsBackEndDateDefault);
          data[prop] = d.toLocaleString("en-US", dateFormatOptions);
        }
        else if (typeof data[prop] === "boolean") {
          data[prop] = false;
        }
        else {
          data[prop] = "";
        }
      }
    }
  }

  //This will get an array out of sessionStorage and (if it exists) copy all of its objects to the "arr" parameter passed in. If it doesn't exist, nothing will happen to "arr". The "arr" should already exist before being passed in.
  static arrayGet(key: string, arr: any[]) {
    const str: string = sessionStorage[key];

    if (str && str != 'null' && str != 'undefined') {
      const newData: any[] = JSON.parse(str);
      arr.length = 0;
      for (let i = 0; i < newData.length; ++i) {
        arr.push(newData[i]);
      }
    }
  }
}

//helper functions to format things, validate things, and compare things
export class Formatter {
  //This is used for grid column sorting. If two number strings are passed in, this will convert them to numbers and compare them.
  static stringNumberComparator(valueA: string, valueB: string, nodeA, nodeB, isInverted) {
    let a: number = 0;
    let b: number = 0;
    //If the number string is in parentheses, it means it's negative, so we take off the parentheses and add a minus sign.
    if (valueA && valueA != '') {
      if (valueA.substring(0, 1) == '(')
        valueA = '-' + valueA.substring(1, valueA.length - 1);
      a = Formatter.toNumber(valueA);
    }
    if (valueB && valueB != '') {
      if (valueB.substring(0, 1) == '(')
        valueB = '-' + valueB.substring(1, valueB.length - 1);
      b = Formatter.toNumber(valueB);
    }
    return a - b;
  }

  //This is used for grid column sorting. If two date strings are passed in, this will convert them to dates and compare them.
  static stringDateComparator(valueA: string, valueB: string, nodeA, nodeB, isInverted) {
    let a: number = 0;
    let b: number = 0;
    //Date.parse() returns the number of ms since Jan 1 1970.
    if (valueA && valueA != '')
      a = Date.parse(valueA);
    if (valueB && valueB != '')
      b = Date.parse(valueB);
    return a - b;
  }

  //This will take a date object or date string and convert it into a date/time string that is consistent throughout the system. This function is often used as the cellRenderer for a grid column; in this case the object passed in is of type ICellRendererParams, so we have to use param.value as the date string.
  static toDateTime(param): string {
    if (param && param.column)
      param = param.value; // param is of type ICellRendererParams

    if (!param || (typeof param == "string" && param.trim() == ""))
      return "";

    //This kludge is for the weird case where param is a string in the format yyyy-mm-dd. In this case, when you create a Date object from the string, it assumes the date is at time 00:00 UTC, which would put it the day before in local time. So we have to add a time of noon on it so that it doesn't do this. Otherwise, I was having problems where a date passed in like "2020-1-2" was being returned as 1/1/2020. This problem doesn't occur if the param passed in is in the format mm/dd/yyyy.
    if (typeof param == "string" && param.indexOf('-') == 4 && param.length < 11)
      param += 'T12:00:00';

    let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true } as const;
    return (new Date(param)).toLocaleString("en-US", options);
  }

  //This will take a date object or date string and convert it into a date that is consistent throughout the system. This function is often used as the cellRenderer for a grid column; in this case the object passed in is of type ICellRendererParams, so we have to use param.value as the date string.
  static toDate(param): string {
    if (param && param.column)
      param = param.value; // param is of type ICellRendererParams

    if (!param || (typeof param == "string" && param.trim() == ""))
      return "";

    //This kludge is for the weird case where param is a string in the format yyyy-mm-dd. In this case, when you create a Date object from the string, it assumes the date is at time 00:00 UTC, which would put it the day before in local time. So we have to add a time of noon on it so that it doesn't do this. Otherwise, I was having problems where a date passed in like "2020-1-2" was being returned as 1/1/2020. This problem doesn't occur if the param passed in is in the format mm/dd/yyyy.
    if (typeof param == "string" && param.indexOf('-') == 4 && param.length < 11)
      param += 'T12:00:00';

    let options = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    return (new Date(param)).toLocaleString("en-US", options);
  }

  // Convert ISO 8601 formatted date in UTC (as string) to local datetime
  // String returned -> this.toDateTime
  static dateToLocalTime(param: string): string {
    var d = new Date(param + "Z"); // Z indicates the parameter is in UTC
    var str = d.toString();
    return this.toDateTime(str);
  }

  // Convert ISO 8601 formatted date in UTC (as string) to local date
  static dateToLocalDate(param: string): string {
    var d = new Date(param + "Z");
    return this.toDate(d.toString());
  }

  //The reason for this is because if the string has commas in it (e.g. "23,000"), the normal JS conversion functions can't handle it.
  static toNumber(param: any): number {
    if (typeof param != "string")
      return param;
    if (param === null || param === undefined)
      return null;
    //Remove anything that isn't a digit, decimal point, or minus sign (-):
    return +param.replace(/[^\d\.\-]/g, "");
  }

  //This will take a string and put a dollar sign at the front and add commas to large numbers. Will not add dollar sign if string is blank. If it's negative, it will surround with parens instead of negative sign.
  static toCurrency(param, addDollarSign: boolean = false) {
    if (param && param.column)
      param = param.value; // param is of type ICellRendererParams

    if (param === null || param === undefined || (typeof param == "string" && param.trim() == ""))
      return "";

    if (typeof param == "string")
      param = Formatter.toNumber(param); //convert to number

    //If the number is negative, make it positive so the minus sign won't display, and put it inside parantheses
    const prefix: string = (param < 0 ? "(" : "");
    const suffix: string = (param < 0 ? ")" : "");

    if (addDollarSign)
      return "$" + prefix + Math.abs(param).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffix;
    else
      return prefix + Math.abs(param).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffix;
  }

  //This will take a string and add commas to large numbers. If it's negative, it will surround with parens instead of negative sign.
  static addCommas(param) {
    if (param && param.column)
      param = param.value; // param is of type ICellRendererParams

    if (param === null || param === undefined || (typeof param == "string" && param.trim() == ""))
      return "";

    if (typeof param == "string")
      param = Formatter.toNumber(param); //convert to number

    //If the number is negative, make it positive so the minus sign won't display, and put it inside parantheses
    const prefix: string = (param < 0 ? "(" : "");
    const suffix: string = (param < 0 ? ")" : "");

    return prefix + Math.abs(param).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffix;
  }

  //returns true only if the passed-in string is a valid email format.
  static validateEmail(str): boolean {
    if (!str)
      return false;
    var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(str.toLowerCase());
  }

  //returns true only if the passed-in string is a valid phone number format ###-###-#### or (###) ###-####.
  static validatePhone(str): boolean {
    if (!str)
      return false;
    var pattern = /(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}/;
    return pattern.test(str.toLowerCase());
  }

  //returns true only if the passed-in string is a string with length 5 or 6 and has all alphanumeric (supports Canadian as well as US)
  static validateZip(str): boolean {
    if (!str)
      return false;
    if (str.length != 5 && str.length != 10)
      return false;
    var pattern = /^\d{5}(?:[- ]?\d{4})?$/i;
    return pattern.test(str.toLowerCase());
  }

  //returns true only if the passed-in string is a valid date format.
  //There are limits on how good this function is. 
  static validateDate(str: string): boolean {
    if (!str || isNaN(Date.parse(str)))
      return false;
    if (typeof str != "string")
      return true;
    let arr: string[];
    if (str.indexOf("/") != -1)
      arr = str.split("/");
    else if (str.indexOf("-") != -1)
      arr = str.split("-");
    else
      return false;

    if (arr.length == 3) { //only accept years greater than 1900. But the date could be separated with either dashes or slashes, and the year could be either the first or third token in the string.
      let year: string;
      if (arr[0].length === 4)
        year = arr[0];
      if (arr[2].length === 4)
        year = arr[2];
      if (parseInt(year) > 1900)
        return true;
    }
    return false;
  }

  //returns true if they are the same, false if different. This is not a deep comparison. The properties of the objects are compared with ==
  static compareArraysOfSimpleObjects(arr1, arr2) {
    if (arr1.length !== arr2.length)
      return false;
    for (let i = 0; i < arr1.length; i++) {
      if (!Formatter.compareSimpleObjects(arr1[i], arr2[i]))
        return false;
    }
    return true;
  }

  //returns true if they are the same, false if different. This is not a deep comparison. The properties of the objects are compared with ==
  static compareSimpleObjects(obj1, obj2) {
    for (var prop in obj1) {
      if (obj1[prop] != obj2[prop])
        return false;
    }
    return true;
  }

  static caseInsensitiveComparator(valueA, valueB) {
    if(valueA == null)
      valueA = '';
    if(valueB == null)
      valueB = '';
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase())
  }

}

//This class is used to fix the issue that when you are keying in a date in a Chrome date input (not using the dropdown picker), and get to the year, it handles it completely wrong. If the year is highlighted and you try to type in "2020", it will clear out the entire input. So this class will attach handlers to the keyup and keydown events for any date inputs to keep track of user input and overwrite the input value if needed. The algorithm will take the old year, shift all the digits to the left (so that the leftmost digit is gone) and put the newest key pressed on the right. So if the old year is 1234, and you type in 5, we'll write in a new year of 2345.
export class ChromeDateFixer {
  static lastDateValue: string = '';

  static initFixer() {
    //This problem shows up on Chrome and Edge (which uses the Chrome engine), but not FF. Since both of them have the word "Chrome" in their userAgent, we can use it to decide whether or not to attach the event handlers to override the behavior
    if (navigator.userAgent.indexOf("Chrome") != -1) {
      $('input[type="date"]').each(function () {
        //We put a custom attribute on the inputs when we add the handlers to them, and then check to make sure that attribute isn't there before adding them. This is in case this method initFixer is called twice, we don't add the handlers twice.
        if (!this["alreadyHasHandlersAdded"]) {
          this["alreadyHasHandlersAdded"] = true;
          //This remembers the value of the date input before the key is pressed
          $(this).keydown(function (event) {
            ChromeDateFixer.lastDateValue = (<HTMLInputElement>event.target).value;
          });
          //This looks at the value of the date input after the key is pressed. When you get the value from the input, it has the format yyyy-mm-dd. So we want to know if the key press affected the year or some other part of the date (month or day). The wrong behavior only affects the year. So if the user is changing the month or day, we don't do overrid anything. So we look at the first four characters of the value to see if it's different than before the key press. If it is, then the user must be changing the year. 
          $(this).keyup(function (event) {
            let input: HTMLInputElement = <HTMLInputElement>event.target;
            if (ChromeDateFixer.lastDateValue != "" && ChromeDateFixer.lastDateValue.substr(0, 4) != input.value.substr(0, 4) && !isNaN(+event.key)) { //We make sure the key pressed is a number. It's possible for the user to change the date with the up/down arrows, but we want to ingore that.
              //User is changing the year, so then we caculate what the year should be, given the old year and the key pressed. Then we combine that with the month/day of the value.
              let newYear = ChromeDateFixer.lastDateValue.substr(1, 3) + event.key;
              input.value = newYear + ChromeDateFixer.lastDateValue.substr(4, 6);
              //This will force Angular to update the model data source with the new value.
              input.dispatchEvent(new Event("input", { bubbles: true }));
            }
          });
        }
      });
    }
  }
}
