//This is a class that came with the template, but Gilmer made some changes.
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal-basic',
  templateUrl: './modal-basic.component.html',
  styleUrls: ['./modal-basic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalBasicComponent implements OnInit {
  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  public visible = false;
  public visibleAnimate = false;

  constructor() {}

  ngOnInit() {

  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 0); //???This was 100 ms in the template. Gilmer lowered it to make it faster. If you take it out of the setTimeout, it will appear immediately rather than fading in.
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 100);; //???This was 300 ms in the template. Gilmer lowered it to make it faster. If you change it to 0 ms, it will disappear immediately.
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      //this.hide();//???Gilmer: if this is not commented out, if the user clicks on the page outside of the dialog, the dialog closes. I like it better where the user is forced to click the button to close the dialog.
    }
  }

}
