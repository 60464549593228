//To use this, put this in your HTML:
//  <message-box #msgBox></message-box>
//Then put this in your TS file:
//  @ViewChild('msgBox') msgBox: MessageBoxComponent;
//Then show it by calling:
//  this.msgBox.show("title", "message", true, () => { alert(1); }, () => { alert(0); });
//
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

@Component({
  selector: 'message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageBoxComponent implements OnInit {
  @ViewChild('modalMessageBox', { static: true }) modalMessageBox;
  @ViewChild('noButtonModal', { static: true }) noButtonModal;
  callbackYes: Function;
  callbackNo: Function;
  modalTitle: string;
  modalMessage: string;
  noButtonText: string;
  showYes: boolean;
  showPrint: boolean;
  includeWarningIcon: boolean;
  static staticInstance: MessageBoxComponent;

  constructor() {}

  ngOnInit() {
    $("#msgBoxModal .basic.modal").css("z-index", "99995"); //The thing that defines which dialog is on top is a div that has the class basic.modal. By default it has a z-index of 1050. This will put this dialog on top of the others.
  }

  // If the yesNo param is true, it will show Yes/No buttons. If it's false, it will show one OK button. This is an optional param, so if you don't pass it in, it's false.
  // callbackYes and callbackNo are optional functions to be called back as the dialog is closed. If yesNo is false, the callbackNo function will be called.
  show(title: string, message: string, yesNo = false, callbackYes?: Function, callbackNo?: Function, showPrintButton: boolean = false, noButtonText: string = "", includeWarningIcon: boolean = false) {
    this.modalMessage = message;
    this.modalTitle = title;
    this.showPrint = showPrintButton;
    this.showYes = yesNo;
    this.noButtonText = noButtonText == "" ? (yesNo ? "No" : "Close") : noButtonText;
    this.includeWarningIcon = includeWarningIcon;
    this.modalMessageBox.show();
    this.callbackNo = callbackNo;
    this.callbackYes = callbackYes;
    var buttonNativeElement = this.noButtonModal.nativeElement;
    if (!yesNo)
      setTimeout(function () { buttonNativeElement.focus(); }, 200); //set focus on button so if user clicks Enter, it will close. Don't do this if there are Yes/No buttons, because it's not obvious which is the default. We have to use setTimeout to set the focus because you can't set focus until it appears, which takes a moment.
  }

  onClickYes() {
    this.modalMessageBox.hide();
    if (this.callbackYes)
      this.callbackYes();
  }

  onClickNo() {
    this.modalMessageBox.hide();
    if (this.callbackNo)
      this.callbackNo();
  }

}
