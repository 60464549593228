//This grid footer component has a button to export the grid data and a select dropdown to change the number of rows in a page.
//If you use the component, you must call initialize() after the page is loaded.
//When the user changes the page size, it's saved in sessionStorage so that if you come back to the page later, it will remember.
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid-community';
import { MessageBoxComponent } from '../message-box/message-box.component';

@Component({
  selector: 'grid-footer',
  templateUrl: './grid-footer.component.html',
  styleUrls: ['./grid-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridFooterComponent implements OnInit {
  sessionStorageKeyPageSize: string;
  uniqueName: string;
  gridOptions: GridOptions;
  columnsToExport: string[];
  msgBox: MessageBoxComponent;
  gridID: string;
  oldGridHeight: string;
  oldGridWidth: string;
  hideActions: boolean;
  static displayedMessageAboutDataNotFittingOnPrintout: boolean = false;
  @ViewChild('pageSizeSelect') pageSizeSelect;
 
  constructor() {}

  ngOnInit() {
  }

  //uniqueName is used in the file name of the exported file and to save the page size in sessionStorage (which is why it must be unique, so each grid in the system has a different value).
  //columnsToExport is an array of the columns to be included if the user exports the grid. If you don't pass it in, the default is to export them all except for any with the header label "Actions"
  initializeFooter(uniqueName: string, gridOptions: GridOptions, defaultPageSize: number = 500, columnsToExport: string[], gridID: string, msgBox: MessageBoxComponent) {
    this.gridOptions = gridOptions;
    this.uniqueName = uniqueName;
    this.sessionStorageKeyPageSize = "gridPageSize" + uniqueName;
    this.columnsToExport = columnsToExport;
    this.gridID = gridID;
    this.msgBox = msgBox;
    let numRecords: string = sessionStorage[this.sessionStorageKeyPageSize];
    if (!numRecords) {
      numRecords = defaultPageSize.toString();
    }
    //See the comment at the top of this function about columnsToExport.
    if (!columnsToExport && gridOptions.columnDefs) {
      this.columnsToExport = [];
      for (let i: number = 0; i < gridOptions.columnDefs.length; i++) {
        if (gridOptions.columnDefs[i].headerName != "Actions")
          this.columnsToExport.push((<ColDef>gridOptions.columnDefs[i]).field);
      }
    }
    this.pageSizeSelect.nativeElement.value = numRecords;
    this.onGridPageSizeChanged();
  }

  //resize the column widths to best fit the grid.
  //This will also set up remembering column and sort settings in sessionStorage, so that when you leave a page and come back, the settings are still there. uniqueName is used to save the settings in sessionStorage (which is why it must be unique, so each grid in the system has a different value). If you don't want the grid column settings saved in sessionStorage, pass in null for uniqueName.
  static sizeColumnWidths(dontResizeColumnNames: string, gridId: string, gridOptions: GridOptions, uniqueName: string, skipResizingUnlessSavedInSesstion: boolean = false) {
    if (!gridOptions.columnApi)
      return;

    //The column settings (which includes their order and their widths) is stored in sessionStorage separately from the sorting info.
    if (uniqueName && window.sessionStorage["column" + uniqueName]) {
      //The columns settings are stored in sessionStorage, so we read them out and apply them to the grid.
      gridOptions.columnApi.setColumnState(JSON.parse(window.sessionStorage["column" + uniqueName]));
    }
    else if (!skipResizingUnlessSavedInSesstion) {
      //dontResizeColumnNames might be a comma-delimited list of column names that shouldn't be resized. We split it into an array to easily search.
      let dontResizeColumnNamesArray: string[] = [];
      if (dontResizeColumnNames)
        dontResizeColumnNamesArray = dontResizeColumnNames.split(",");
      //This makes an array of all the column IDs and passes it to autoSizeColumns, so all the columns will be sized to try to appropriately fit the data in the grid.
      var allColumnIds = [];
      gridOptions.columnApi.getAllColumns().forEach(column => {
        if (dontResizeColumnNamesArray.indexOf(column.getColId()) == -1) //there might be a column we don't want to resize, like the Action column, because we hardcode its width in the gridOptions
          allColumnIds.push(column.getColId());
      });
      gridOptions.columnApi.autoSizeColumns(allColumnIds);

      // if the grid has service areas or specialties, keep the initial width at a max of 800px.  The user can adjust the column to be bigger if they prefer.
      allColumnIds.forEach((id) => {
        if(gridOptions.columnApi.getColumn(id).getActualWidth() > 800) {
          gridOptions.columnApi.setColumnWidth(id, 800);
        }
      })

      //If the grid is wider than the width of all the columns, the grid will have an extra column-ish area to the right, which looks weird. So this code below looks for that condition and if it exists, changes the method of sizing the columns to sizeColumnsToFit(), which will increase the size of all the columns to take up the whole width of the grid.
      let cols = gridOptions.columnApi.getColumnState();
      let i: number;
      let colWidths: number = 0;
      for (i = 0; i < cols.length; ++i) {
        colWidths += cols[i].width;
      }
      let gridWidth: number = $("#" + gridId).width();
      if (gridWidth > colWidths) {
        gridOptions.api.sizeColumnsToFit();
        //There's a weird issue where sizeColumnsToFit() is supposed to make all the columns fit perfectly. But it ends up being 1 pixel too wide, which causes a scroll bar to appear at the bottom of the grid, so this will decrease the width of the last column by one pixel.
        cols = gridOptions.columnApi.getColumnState();
        if (cols.length != 0) {
          cols[cols.length - 1].width = cols[cols.length - 1].width - 1;
          gridOptions.columnApi.setColumnState(cols);
        }
      }
    }

    if (uniqueName) {
      if (window.sessionStorage["sort" + uniqueName])
        gridOptions.api.setSortModel(JSON.parse(window.sessionStorage["sort" + uniqueName])); //The sort settings are stored in sessionStorage, so we read them out and apply them to the grid.

      //Set up various event handlers so that if the grid settings are changed by the user, we save them in sessionStorage.
      gridOptions.onSortChanged = () => { GridFooterComponent.saveState(gridOptions.api.getSortModel(), "sort" + uniqueName) };
      gridOptions.onColumnResized = () => { GridFooterComponent.saveState(gridOptions.columnApi.getColumnState(), "column" + uniqueName) };
      gridOptions.onColumnMoved = () => { GridFooterComponent.saveState(gridOptions.columnApi.getColumnState(), "column" + uniqueName) };
    }
  }

  //event for if the user clicks the button to export.
  public onExportGrid() {
    //We have to pass an array of columns to the export function, so it knows which cols to export. But we want to skip any that are currently invisible.
    let cols: string[] = [];
    for (let i = 0; i < this.columnsToExport.length; i++)
      if (this.gridOptions.columnApi.getColumn(this.columnsToExport[i]).isVisible())
        cols.push(this.columnsToExport[i]);

    let params = {
      fileName: this.uniqueName + "_Grid_Data.csv",
      columnKeys: cols
    };
    this.gridOptions.api.exportDataAsCsv(params);
  }

  //event for if the user changes the page size.
  public onGridPageSizeChanged() {
    let pageSize: string = this.pageSizeSelect.nativeElement.value;
    this.gridOptions.api.paginationSetPageSize(Number(pageSize));
    sessionStorage[this.sessionStorageKeyPageSize] = pageSize;
  }

  //This will save the state of something in session storage using a key
  static saveState(obj: any, key: string) {
    window.sessionStorage[key] = JSON.stringify(obj);
  }

  //event handler when the user clicks the Print button. 
  onPrint() {
    //We have to change some things in the layout of the page to make it suitable for printing. After we do this, we want to pause for 300 ms before showing the print dialog because it takes some time for the browser to rerender stuff, and once we show the print dialog, it stops rerendering. But we also want to show the user a dialog with some info (about how to fit stuff on a printed page) once per login session (i.e. the first time they print after logging in but not every time). So if we've never shown the dialog yet, we show that, and when they close that dialog, we'll show the print dialog. If we've already shown the dialog about fitting stuff on a printout, we just delay for 300 ms and then show the print dialog.
    let grid = document.getElementById(this.gridID);
    if (!grid)
      this.msgBox.show("Warning", "The grid has not been initialized.", false);
    //Per the instructions from ag-grid, we must get rid of the height and width properties of the grid and then call api.setDomLayout('print'). This will reformat the grid for printing.
    this.oldGridHeight = grid.style.height;
    this.oldGridWidth = grid.style.width;
    grid.style.height = '';
    grid.style.width = '';
    this.gridOptions.api.setDomLayout('print');
    //If there's an Action column, make it invisible
    for (let i: number = 0; i < this.gridOptions.columnDefs.length; i++) {
      if (this.gridOptions.columnDefs[i].headerName == "Actions"){
        // @ts-ignore
        this.hideActions = this.gridOptions.columnDefs[i].hide;
        this.gridOptions.columnApi.setColumnVisible((<ColDef>this.gridOptions.columnDefs[i]).field, false);
      }
    }

    if (GridFooterComponent.displayedMessageAboutDataNotFittingOnPrintout) {
      setTimeout(() => {
        this.finishPrint();
      }, 300);
    }
    else {
      this.msgBox.show("Information", "If the data is too wide to fit on a printed page, you can make the columns more narrow and print again. You may also export to CSV and print the data from a spreadsheet program. Many browsers have options to change the margins, scale, or landscape mode on the settings of their print dialog to allow more data to fit on the page.<br><br>Click Close to continue printing.", false, null,
        () => {
          //This function will be called when the user clicks the Close button. But use setTimeout to wait some milliseconds because the msgBox dialog has to disappear before we call print().
          setTimeout(() => {
            this.finishPrint();
          }, 200);
        });
      GridFooterComponent.displayedMessageAboutDataNotFittingOnPrintout = true;
    }
  }

  //This will call print(), which opens the browser's print dialog. And then we set the grid display properties back to normal since it was reformatted for printing in onPrint().
  finishPrint() {
    print(); //this is a global JS function
    let grid = document.getElementById(this.gridID);
    grid.style.height = this.oldGridHeight;
    grid.style.width = this.oldGridWidth;
    this.gridOptions.api.setDomLayout(null);
    //If there's an Action column, make it visible
    for (let i: number = 0; i < this.gridOptions.columnDefs.length; i++) {
      if (this.gridOptions.columnDefs[i].headerName == "Actions" && !this.hideActions)
        this.gridOptions.columnApi.setColumnVisible((<ColDef>this.gridOptions.columnDefs[i]).field, true);
    }
  }
}
