// To use this, put this in your HTML:
//  <date-range-picker #dateRangePicker></date-range-picker>
// Then put this in your TS file:
//  @ViewChild('dateRangePicker') dateRangePicker: DateRangePickerComponent;
// Then initialize it by calling the init() function. 
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Option, ServiceArea, WebService } from '../web-service/web.service';
import { ChromeDateFixer } from '../../shared/utilities/utilities';

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DateRangePickerComponent implements OnInit {
  filterData: FilterData;
  callbackRefreshData: Function;
  yearOptions: Option[];

  dateRangeOptions = [
    { optionName: 'Custom Dates', optionNo: 14 },
    { optionName: 'Quarter1', optionNo: -1 },
    { optionName: 'Quarter2', optionNo: -2 },
    { optionName: 'Quarter3', optionNo: -3 },
    { optionName: 'Quarter4', optionNo: -4 },
    { optionName: 'Year', optionNo: 13 },
    { optionName: 'January', optionNo: 1 },
    { optionName: 'February', optionNo: 2 },
    { optionName: 'March', optionNo: 3 },
    { optionName: 'April', optionNo: 4 },
    { optionName: 'May', optionNo: 5 },
    { optionName: 'June', optionNo: 6 },
    { optionName: 'July', optionNo: 7 },
    { optionName: 'August', optionNo: 8 },
    { optionName: 'September', optionNo: 9 },
    { optionName: 'October', optionNo: 10 },
    { optionName: 'November', optionNo: 11 },
    { optionName: 'December', optionNo: 12 }
  ];

  constructor(private webService: WebService) {
    this.filterData = new FilterData();

    // Fetch years list
    const currentYear = new Date().getFullYear();
    this.yearOptions = [];
    for (let i = 2022; i <= currentYear; i++) {
      this.yearOptions.push(new Option(i, i.toString()));
    }
  }

  ngOnInit() {
    ChromeDateFixer.initFixer();
  }

  // Hide the year selector if "Year" or "Custom Date" are selected
  showYearSelector() {
    return this.filterData.periodNo != null && this.filterData.periodNo < 14;
  }

  // Hide custom dates unless specifically selected
  showCustomDates() {
    return this.filterData.periodNo == 14;
  }

  // Call this to initalize the control.
  initDateRangePicker(callbackRefreshData: Function) {
    this.callbackRefreshData = callbackRefreshData;
  }

  clearRange() {
    this.filterData = new FilterData();
    this.refreshData();
  }

  clearYear() {
    this.filterData.yearNo = null;
    this.filterData.periodNo = null;
    this.refreshData();
  }

  // Main ingress for dynamic data
  refreshData() {
    if (this.callbackRefreshData)
      this.demuxDates(this.filterData.periodNo); // Updates state of filterData variables

    if (this.validDateInput(this.filterData))
      this.callbackRefreshData(this.filterData); // Run the callback defined by the initiating component. This is the piece that makes the date picker able to be dynamically inserted into any form.
  }

  // Because date range can be quarterly or monthly, we must set fromDate and toDate accordingly  
  demuxDates(dateRangeNo: number): void {
    var year;
    (this.filterData.yearNo == null) ? year = new Date().getFullYear() : year = this.filterData.yearNo;

    // Happens in the event that a user clears the year selector
    if (dateRangeNo == null) {
      this.filterData.fromDate = new Date('1/1/' + year);
      this.filterData.toDate = new Date('12/31/' + year);
      return;
    }

    // Ideally this would be in its own class with enums & decorator functionality
    switch(dateRangeNo) {
      // Quarter 1
      case -1: {
        this.filterData.yearNo = year;
        this.filterData.fromDate = new Date('1/1/' + year);
        this.filterData.toDate = new Date('3/31/' + year);
        break;
      }
      // Quarter 2
      case -2: {
        this.filterData.yearNo = year;
        this.filterData.fromDate = new Date('4/1/' + year);
        this.filterData.toDate = new Date('6/30/' + year);
        break;
      }
      // Quarter 3
      case -3: {
        this.filterData.yearNo = year;
        this.filterData.fromDate = new Date('7/1/' + year);
        this.filterData.toDate = new Date('9/30/' + year);
        break;
      }
      // Quarter 4
      case -4: {
        this.filterData.yearNo = year;
        this.filterData.fromDate = new Date('10/1/' + year);
        this.filterData.toDate = new Date('12/31/' + year);
        break;
      }
      // Year
      case 13: {
        // Year to Date
        if (this.filterData.yearNo == null) {
          this.filterData.yearNo = new Date().getFullYear();
          this.filterData.fromDate = new Date('1/1/' + this.filterData.yearNo);
          this.filterData.toDate = new Date();
        } else {
          // Full Year
          this.filterData.fromDate = new Date('1/1/' + this.filterData.yearNo);
          this.filterData.toDate = new Date('12/31/' + this.filterData.yearNo);
        }
        break;
      }
      // Custom Dates
      case 14: {
        this.filterData.fromDate = new Date($("#customDateFrom").val() + " 00:00:00");
        this.filterData.toDate   = new Date($("#customDateTo").val() + " 00:00:00");
        break;
      }
      // Months
      default: {
        if (dateRangeNo < 13) {
          var monthSelector = this.dateRangeOptions.filter(option => option.optionNo == dateRangeNo);
          var month = monthSelector[0].optionName;
          var startDate = new Date(month + " " + year);

          this.filterData.yearNo = year;
          this.filterData.fromDate = startDate;
          this.filterData.toDate = new Date(year, startDate.getMonth() + 1, 0); // This will give the last day of the month
        }
      }
    }
  }

  // Assert that entered date info for CUSTOM DATES is greater than Jan 1, 1950
  validDateInput(dateData: FilterData): boolean {
    this.clearValidationClasses();

    var datesValid = true;
    const dbMinDate = new Date('1/1/1850'); // Don't send nonsensical data to the API

    // No action to take, custom dates not selected.
    if (dateData.periodNo != 14)
      return datesValid;

    if (new Date(dateData.fromDate) < dbMinDate) {
      // Add front end message and red form box
      $("#customDateFrom").addClass("validation-error")
      datesValid = false;
    }
    if (new Date(dateData.toDate) < dbMinDate) {
      // Add front end message and red form box
      $("#customDateTo").addClass("validation-error")
      datesValid = false;
    }

    return datesValid;
  }

  clearValidationClasses(): void {
    $("#customDateFrom").removeClass("validation-error")
    $("#customDateTo").removeClass("validation-error")
  }
}

export class FilterData {
    periodNo: number;
    yearNo:   number;
    fromDate: Date;
    toDate:   Date;
}
