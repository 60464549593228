import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'projects',
        loadChildren: () => import('./pages/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'contractors',
        loadChildren: () => import('./pages/contractors/contractors.module').then(m => m.ContractorsModule)
      },
      {
        path: 'homeowners',
        loadChildren: () => import('./pages/homeowners/homeowners.module').then(m => m.HomeownersModule)
      },
      {
        path: 'project-forms',
        loadChildren: () => import('./pages/decision-trees/decision-trees.module').then(m => m.DecisionTreesModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'projects/project-overview',
        loadChildren: () => import('./pages/project-specs/project-specs.module').then(m => m.ProjectSpecsModule)
      },
      {
        path: 'projects/messaging',
        loadChildren: () => import('./pages/messaging/messaging.module').then(m => m.MessagingModule)
      },
      {
        path: 'projects/milestones',
        loadChildren: () => import('./pages/milestones/milestones.module').then(m => m.MilestonesModule)
      },
      {
        path: 'projects/materials',
        loadChildren: () => import('./pages/materials/materials.module').then(m => m.MaterialsModule)
      },
      {
        path: 'projects/tasks',
        loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule)
      },
      {
        path: 'projects/notes',
        loadChildren: () => import('./pages/notes/notes.module').then(m => m.NotesModule)
      },
      {
        path: 'projects/important-docs',
        loadChildren: () => import('./pages/important-docs/important-docs.module').then(m => m.ImportantDocsModule)
      },
      {
        path: 'projects/design-inspiration',
        loadChildren: () => import('./pages/design-inspiration/design-inspiration.module').then(m => m.DesignInspirationModule)
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'reset-password',
        loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
