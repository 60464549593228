//this is the template of the page before the user has logged in. It hosts the login page and any other pages we create (e.g. forgot pwd, if we create that at some point).
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
