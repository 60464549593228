//this class intercepts every web service API call to do a few things, like:
// - tell the LoaderService that an API call is active, which the spinner component will use to show the spinner.
// - get the security token from every response header and save it in sessionStorage.
// - put the security token into every request header.
// - catch connection errors from the server and handle them consistently. We pop up a message box for the user with the error. But if the error is a 401, then we automatically log out the user because it's a security error. The most likely cause of a security error is that it's been too long since the user has accessed the API, so their security token has expired, which is cause for auto-logout.
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse, HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MessageBoxComponent } from '../message-box/message-box.component';

declare var MatchingUiVersion: string; // This is a copy of a variable declared in config.js

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests: number = 0;

  constructor(private loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    //Gilmer: I looked at a bunch of different examples of how to do this online, and they were all a bit different. This one made the most sense to me. You have the totalRequests variable in case there's more than one at a time. You increase totalRequests when the request is generated and decrease it when the web call is finished.
    //Gilmer: A number of examples I looked at said you'd know when to hide the spinner because the response comes back or there's an error. But another example I saw showed that you can do it in the finalize. That's why there's a decreaseRequests() call in two places that are commented out in case we need to go back to that.
    this.totalRequests++;
    this.loaderService.isLoading = true;
    //To add a header for the security token to the request, we have to clone the request object because it is immutable. See https://angular.io/guide/http#immutability. We store the validation token in SessionStorage. If it's there, clone the request and add the token to the headers. If it's not there (e.g. we are on the login page so we don't have a token yet), then just use the request without cloning.
    let clonedRequest: HttpRequest<any>;
    const valToken: string = sessionStorage.validationToken;
    if (valToken && valToken.length > 0)
      clonedRequest = request.clone({ setHeaders: { ValidationToken: valToken } });
    else
      clonedRequest = request;

    return next.handle(clonedRequest).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          // If there's a security token in the response headers, save it in sessionStorage to pass back with the next request.
          const token: string = res.headers.get("ValidationToken");
          sessionStorage.lastWebApiCall = new Date().getTime();

          if (token)
            sessionStorage.validationToken = token;
          //this.decreaseRequests();

          // We get a version string from the API; it's take from web.config and sent back to the UI in the response header. The UI uses this to compare to its own version string in its config file (MatchingUiVersion). Whenever we do a new build, both of these should be updated to match each other. It doesn't matter what they are as long as they are the same. I suggest "1", then "2", etc. The reason for doing this is if someone is using the website, and we do an udpate, their UI will no longer match with the API. The UI will notice the mismatch and prompt the user to update their UI.
          const version: string = res.headers.get("MatchingUiVersion");
          if (MessageBoxComponent.staticInstance && version && version != MatchingUiVersion) {
            //We want to ask the user if they want to refresh their browser. If they answer yes, we'll trigger the refresh. But we don't want to ask them with every single API call because it's possible they are in the middle of doing something and want to wait a few minutes. So if they say no, we won't ask them again for 1 minute. We keep track of the last time we asked with sessionStorage.timeAskedUserQuestion
            if (!sessionStorage.timeAskedUserQuestion || (new Date().getTime() - parseInt(sessionStorage.timeAskedUserQuestion)) / 60000 > 1) { //divide by 60000 to convert from ms to minutes
              sessionStorage.timeAskedUserQuestion = new Date().getTime(); //converts the date to milliseconds

              let msg: string = `There has been an important update to the BuildSAGE system. You need to refresh your browser, clearing the cache for this site, to get the latest version. Would you like to do this now? Clicking "Yes" will attempt to trigger the refresh. `;
              if (navigator.platform.toLowerCase().indexOf("win") != -1) { //windows
                msg += "You can also press CTRL-F5 to trigger the refresh.";
              }
              else if (navigator.platform.toLowerCase().indexOf("mac") != -1) { //mac
                if (navigator.userAgent.toLowerCase().indexOf("chrome") != -1)
                  msg += "You can also press Command, Shift and 'R' to trigger the refresh."; //Chrome
                else
                  msg += "You can also press Command and 'E' to trigger the refresh."; //Safari
              }
              else if (navigator.platform.indexOf("iP") != -1) { //ios
                if (navigator.userAgent.toLowerCase().indexOf("crios") != -1) //Chrome
                  msg += "For Chrome on iOS: Tap More, tap History, tap Clear Browsing Data, and clear 'Cookies, Site Data.'"
                else //Safari, hopefully
                  msg += "For Safari on iOS: Open the Settings app, tap Safari, scroll down and tap Advanced, tap Website Data, tap Edit in the upper-right corner, tap BuildSage.com to delete the data from this site.";
              }

              MessageBoxComponent.staticInstance.show("Warning", msg, true,
              () => {
                //We trigger a refresh by adding a random string to the query string, which will reload the page, and should get all new files
                let randomParam: string = '' + Math.floor(Math.random() * 100000) + 1000;
                window.location.href = window.location.href.split('?')[0] + '?' + randomParam + '=reload'
              });
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let isAuthorizationError: boolean = false;
        //this.decreaseRequests();
        let errMsg = '';
        if (error.error instanceof ErrorEvent) {
          // Client Side Error (???Gilmer: I copied this from somewhere and am not sure it's right)
          errMsg = `Client Error: ${error.error.message}`;
        }
        else {
          // Server Side Error
          errMsg = `Server Error. Code: ${error.status},  Message: ${error.message}`;
          if (error.error && error.error.message)
            errMsg += ' ---- Inner Error: ' + error.error.message;
          if (error.status == 401) {
            console.log("Auto log out user because an API call had an authentication error.");
            isAuthorizationError = true;
            sessionStorage.clear();
            sessionStorage.logoutReason = "AuthorizationError";
            window.location.assign(document.getElementsByTagName('base')[0].href + "login");
          }
        }
        //???I don't know if this is what we want to do.
        if (!isAuthorizationError) {
          if (MessageBoxComponent.staticInstance)
            MessageBoxComponent.staticInstance.show("Error connecting to the server", errMsg, false);
          else
            console.log(errMsg);
        }
        return throwError(errMsg);
      }),
      finalize(() => {
        this.decreaseRequests();
      })
    );
  }

  private decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests === 0) {
      this.loaderService.isLoading = false;
    }
  }
}
