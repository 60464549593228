<div class="p-2 d-inline-flex" style="width: 290px;">
  <label for="daterange" class="my-1 mx-1">Date range &nbsp;</label>
  <ng-select [(ngModel)]="filterData.periodNo" [items]="dateRangeOptions" bindLabel="optionName" bindValue="optionNo" [appendTo]="'body'" (change)="refreshData()" (clear)="clearRange()" class="col-xs-2" style="width:70%;"></ng-select>
</div>

<div class="p-2 d-inline-flex" *ngIf="showYearSelector()">
  <label for="yearNo" class="my-1">Year &nbsp;</label>
  <ng-select [(ngModel)]="filterData.yearNo" [items]="yearOptions" bindLabel="optionName" bindValue="optionNo" [appendTo]="'body'" (change)="refreshData()" (clear)="clearYear()" class="" style="display: margin-right: 5px; width: 130px"></ng-select>
</div>

<span *ngIf="showCustomDates()">
  <span style="white-space: nowrap">
    From
    <input type="date" id="customDateFrom" maxlength="45" (blur)="refreshData()" (keydown.enter)="refreshData" class="form-control" style="display: inline; width: 157px" min="2000-01-01" max="2999-12-31" trimOnBlur>
  </span>
  <span style="white-space: nowrap">
    To
    <input type="date" id="customDateTo" maxlength="45" (blur)="refreshData()" (keydown.enter)="refreshData()" class="form-control" style="display: inline; margin-right: 5px; width: 157px" min="2000-01-01" max="2999-12-31" trimOnBlur>
  </span>
</span>
