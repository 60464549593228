<div class="footer" style="position: relative;  font: 400 14px 'Helvetica Neue', sans-serif; width: 240px">
  <button (click)="onPrint()" [disabled]="!gridOptions" style="height: 22px; line-height: 2px; font: 400 14px 'Helvetica Neue', sans-serif;">Print</button>&nbsp;
  <button (click)="onExportGrid()" [disabled]="!gridOptions" style="height: 22px; line-height: 2px; font: 400 14px 'Helvetica Neue', sans-serif;">Export</button>
  &nbsp;Page size:
  <select (change)="onGridPageSizeChanged()" #pageSizeSelect style="height: 23px; font: 400 14px 'Helvetica Neue', sans-serif;">
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
    <option value="200">200</option>
    <option value="500" selected="">500</option>
    <option value="1000">1000</option>
  </select>
</div>
