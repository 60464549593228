import { ErrorHandler, Injectable } from '@angular/core';

declare var debugModeForErrors: boolean;//This tells the compiler that it's a global variable coming from another file. In our case, it's coming from config.js.

@Injectable()

export class GlobalErrorHandler implements ErrorHandler {
  constructor() { }

  handleError(error: Error) {
    //Occasionally when you click on one of the pages in the menu, it will fail to load and create an error that contains the string "ChunkLoadError." We do something special when this happens.
    if (error.message && error.message.indexOf("ChunkLoadError") != -1)
      alert("The screen failed to load. Please click on the screen in the menu again.");
    else if (error.message && error.message.indexOf("ExpressionChangedAfterIt") != -1) {
      //This is a special Angular error that I haven't been able to figure out how to fix. So ignore it because sometimes it keeps popping up many times.
    }
    else if (!sessionStorage.validationToken) {
      //If we just got a 401 error from the server, in loader.interceptor.ts we empty the session storage and do an auto-logout. So if this entry isn't in sessionStorage, we don't need to pop up an error.
    }
    else if (debugModeForErrors)
      alert(error.message);
    console.error("Error from global error handler", error);
    //I had an instance where there was an error, and when I rethrew it, it messed something up within JS or angular or something, and then future things didn't work right. When I commented out this line, it no longer had the problem.
    //throw error;
  }
  
}
