import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccordionAnchorDirective} from './accordion/accordionanchor.directive';
import {AccordionLinkDirective} from './accordion/accordionlink.directive';
import {AccordionDirective} from './accordion/accordion.directive';
import {HttpClientModule} from '@angular/common/http';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {TitleComponent} from '../layout/admin/title/title.component';
import {CardComponent} from './card/card.component';
import {CardToggleDirective} from './card/card-toggle.directive';
import {ModalBasicComponent} from './modal-basic/modal-basic.component';
import {MessageBoxComponent} from './message-box/message-box.component';
import {DateRangePickerComponent} from './date-range-picker/date-range-picker.component';
import {GridFooterComponent} from './grid-footer/grid-footer.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {ClickOutsideModule} from 'ng-click-outside';
import { TrimOnBlurModule } from 'ng2-trim-on-blur';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { NgxMaskModule, IConfig } from 'ngx-mask'; //needed for textbox mask
import { InputTrimDirective } from './directives/input-trim.directive';
import { DateInputDirective } from './directives/input-date.directive';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}; //needed for textbox mask

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    AgGridModule.withComponents([]),
    NgxMaskModule.forRoot(options), //needed for textbox mask
    FormsModule,
    NgSelectModule
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    InputTrimDirective,
    DateInputDirective,
    HttpClientModule,
    PerfectScrollbarModule,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    MessageBoxComponent,
    DateRangePickerComponent,
    AgGridModule,
    NgxMaskModule,
    GridFooterComponent,
    SpinnerComponent,
    ClickOutsideModule,
    TrimOnBlurModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    InputTrimDirective,
    DateInputDirective,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    MessageBoxComponent,
    DateRangePickerComponent,
    GridFooterComponent,
    SpinnerComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule { }
