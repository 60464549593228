import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
  rolesCanView: string;
  tooltip: string;
  badge: BadgeItem;
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  tooltip: string;
  badge: BadgeItem;
  children?: ChildrenItems[];
  rolesCanView: string;
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const SUB_MENU_ITEMS: ChildrenItems[] = [
  {
    state: 'tasks',
    name: 'Tasks',
    type: 'link',
    rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
    tooltip: 'Review your Project\'s current and closed Tasks and complete open Tasks assigned to you',
    badge: {
      type: '',
      value: ''
    }
  },
  {
    state: 'messaging',
    name: 'Messages',
    type: 'link',
    rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
    tooltip: 'Communicate with your Project Team or privately with BuildSAGE about your Project',
    badge: {
      type: '',
      value: ''
    }
  },
  {
    state: 'design-inspiration',
    name: 'Design Inspirations',
    type: 'link',
    rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
    tooltip: 'Share photos, drawings, and links to examples of the types of designs that inspire you for your project',
    badge: {
      type: '',
      value: ''
    },
  },
  {
    state: 'milestones',
    name: 'Milestones',
    type: 'link',
    rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
    tooltip: 'Review important goals and target dates for your Project',
    badge: {
      type: '',
      value: ''
    }
  },
  {
    state: 'important-docs',
    name: 'Important Documents',
    type: 'link',
    rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
    tooltip: 'Upload and review official Project documents for your Project',
    badge: {
      type: '',
      value: ''
    }
  },
  {
    state: 'project-overview',
    name: 'Project Overview',
    type: 'link',
    rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
    tooltip: 'Review a summary overview of your Project',
    badge: {
      type: '',
      value: ''
    }
  },
  {
    state: 'notes',
    name: 'Notes',
    type: 'link',
    rolesCanView: 'superadmin,administrator,homeowner rep,office manager',
    tooltip: '',
    badge: {
      type: '',
      value: ''
    }
  },
];

const MAIN_MENU_ITEMS = [
  {
    label: '',
    main: [ //See https://icon-sets.iconify.design/bx/ for icons to fill in the badge type and value properties
      {
        state: 'projects',
        name: 'My Projects',
        type: 'link',
        rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
        tooltip: 'Manage your BuildSAGE projects',
        badge: {
          type: "bxs",
          value: "school"
        },
        children: SUB_MENU_ITEMS,
      },
      {
        state: 'users',
        name: 'Users',
        type: 'link',
        rolesCanView: 'superadmin,administrator,office manager',
        tooltip: '',
        badge: {
          type: "bxs",
          value: "group"
        },
      },
      {
        state: 'contractors',
        name: 'Contractors',
        type: 'link',
        rolesCanView: 'superadmin,administrator,office manager',
        tooltip: '',
        badge: {
          type: "bxs",
          value: "wrench"
        },
      },
      {
        state: 'homeowners',
        name: 'Homeowners',
        type: 'link',
        rolesCanView: 'superadmin,administrator,office manager',
        tooltip: '',
        badge: {
          type: "bxs",
          value: "home-circle"
        },
      },
      {
        state: 'project-forms',
        name: 'Project Forms',
        type: 'link',
        rolesCanView: 'superadmin,administrator',
        tooltip: '',
        badge: {
          type: "bx",
          value: "file"
        }
      },
      {
        state: 'reports',
        name: 'Reports',
        type: 'link',
        rolesCanView: 'superadmin,administrator,data analyst,office manager',
        tooltip: '',
        badge: {
          type: "bxs",
          value: "bar-chart-alt-2"
        }
      },
      {
        state: 'notifications',
        name: 'Notifications',
        type: 'link',
        rolesCanView: 'superadmin,administrator,homeowner rep,office manager,contractor,homeowner',
        tooltip: 'Messages from your Project Team and updates on the status of your Projects',
        badge: {
          type: 'bxs',
          value: 'bell'
        }
      },
      {
        state: 'account',
        name: 'My Account',
        type: 'link',
        rolesCanView: 'superadmin,contractor,homeowner',
        tooltip: 'Review and manage your BuildSAGE account details',
        badge: {
          type: 'bx',
          value: 'user-circle'
        }
      },
    ]
  }
];

@Injectable()
export class MenuItems {
  getAllMain(): Menu[] {
    return MAIN_MENU_ITEMS;
  }
  getAllSub(): ChildrenItems[] {
    return SUB_MENU_ITEMS;
  }
}
