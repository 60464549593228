<app-modal-basic #modalMessageBox id="msgBoxModal">
  <div class="app-modal-header modal-padding">
    <h6 class="modal-title">
      <img *ngIf="!includeWarningIcon" src="../../../../assets/images/favicon_96.png" height="38"/> 
      <img *ngIf="includeWarningIcon" src="../../../../assets/images/warning-icon.png" height="38"/> 
      {{modalTitle}}
    </h6>
  </div>
  <div style="max-height: 70vh; overflow: auto;"class="app-modal-body modal-padding" [innerHTML]="modalMessage">
  </div>
  <div class="app-modal-footer modal-padding" style="margin-top: 10px">
    <div class="button-group-align-right">
      <button type="button" class="btn btn-primary" (click)="onClickYes()" *ngIf="showYes">Yes</button>
      &nbsp;&nbsp;&nbsp;
      <button #noButtonModal type="button" class="btn" [ngClass]="showYes ? 'btn-cancel' : 'btn-primary'" (click)="onClickNo()">{{noButtonText}}</button>
      &nbsp;&nbsp;&nbsp;
      <button type="button" class="btn btn-cancel" onclick="window.print()" *ngIf="showPrint">Print</button>
    </div>
  </div>
</app-modal-basic>
