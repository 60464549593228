import {Component, OnInit} from '@angular/core';
import {NavigationStart, NavigationEnd, Router} from '@angular/router';
import { filter } from 'rxjs/operators';
declare const gtag: Function;
declare var gaTrackingId : string; // This tells the compiler that it's a global variable coming from another file. In our case, it's coming from config.js.
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BuildSAGE';

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  /** Add Google Analytics Script Dynamically */
  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          console.log(event)
            gtag('config', gaTrackingId,
                {
                    page_path: event.urlAfterRedirects
                }
            );
        });
}

}
