<!--ORIGINAL SPINNER-->
<!--<div id="http-loader" *ngIf="isSpinnerVisible || loaderService.isLoading" style="z-index: 99999 !important">
  <div id="preloader">
    <div id="loader"></div>
  </div>
</div>-->
<!--BLOCK SPINNER-->
<!--<div id="http-loader" *ngIf="isSpinnerVisible || loaderService.isLoading" style="z-index: 99999 !important">

  <div class="loader">
    <div class="square"></div>
    <div class="square"></div>
    <div class="square last"></div>
    <div class="square clear"></div>
    <div class="square"></div>
    <div class="square last"></div>
    <div class="square clear"></div>
    <div class="square "></div>
    <div class="square last"></div>
  </div>

</div>-->
<!--RIPPLE SPINNER-->
<div id="http-loader" *ngIf="isSpinnerVisible || loaderService.isLoading" style="z-index: 99999 !important">
  <div class="Loader"></div>
  </div>
